import React, {useContext, useEffect, useState} from 'react'
import {FormattedDate} from 'react-intl'
import {useDispatch, useSelector} from 'react-redux'
import {cx} from '@linaria/core'
import {CarouselWidgetsContext} from 'components/CarouselWidgets/context/CarouselWidgetsContext'
import {HighlightedTitle} from 'components/Home/HighlightedTitle'
import {useNavigateToSearchWithTrigger} from 'hooks/useNavigateToSearch'
import {getSearchContext} from 'middleware/analytics/selectors'
import {SearchTrigger} from 'modules/searchBox/types'
import {pick} from 'ramda'
import {getDestinationParameter} from 'utils/searchParams'

import {Button} from '@daedalus/atlas/Button/Button'
import {useDeviceLayout} from '@daedalus/atlas/context/deviceLayout'
import {Box} from '@daedalus/atlas/helpers/Box'
import ContentWrapper from '@daedalus/atlas/helpers/ContentWrapper'
import {Text} from '@daedalus/atlas/Text'
import {useInView} from '@daedalus/core/src/_web/utils/browser/hooks/useInView'
import {trackEvent} from '@daedalus/core/src/analytics/modules/actions'
import {
  Action,
  AnalyticsContext,
  Category,
  Entity,
  Team
} from '@daedalus/core/src/analytics/types/Events'
import FormattedMessageWrapper from '@daedalus/core/src/localization/components/FormattedMessage'
import {dateStringToMiddayDate} from '@daedalus/core/src/utils/date'
import {GuestAndRoomText} from '@daedalus/shared/src/search/GuestAndRoomsText'

import {SimilarHotels} from '../components/SimilarHotels'
import {SeeAllText, TopSection, TopSectionText} from '../components/TopSection'
import {DESTINATION_KEYS, ITINERARY_KEYS} from '../utils/constants'

export const SuggestedHotels = () => {
  const {isMobile} = useDeviceLayout()
  const {searchHistory} = useContext(CarouselWidgetsContext)
  const {ref, inView} = useInView({triggerOnce: true, threshold: 0.5})
  const [alreadyTrackedCarousel, setAlreadyTrackedCarousel] = useState(false)
  const searchContext = useSelector(getSearchContext)

  const searchItem = searchHistory[0]

  const {hotelId, placeId, placeName, objectType, rooms, checkIn, checkOut} =
    searchItem || {}

  const baseSearchParameters = {
    rooms,
    checkIn,
    checkOut,
    userSearch: '1' as const,
    homeSearch: '1' as const
  }
  const navigateToSearch = useNavigateToSearchWithTrigger(
    SearchTrigger.ContinueSearchWidget
  )

  const dispatch = useDispatch()

  const trackingPayload = {
    component: 'SimilarProperties',
    team: Team.Retention
  }

  const onHotelClick = ({hotelId}: {hotelId: string}) =>
    navigateToSearch({...baseSearchParameters, hotelId})

  const onSeeAllClick = () => {
    dispatch(
      trackEvent({
        category: Category.User,
        entity: Entity.CarouselSeeAll,
        action: Action.Clicked,
        ...trackingPayload,
        payload: {
          searchItem: {
            ...(hotelId && {hotelId}),
            ...(placeId && {placeId}),
            checkIn,
            checkOut,
            rooms
          }
        }
      })
    )

    const destinationParameters = getDestinationParameter(
      objectType,
      hotelId ?? placeId ?? placeName
    )
    navigateToSearch({...baseSearchParameters, ...destinationParameters})
  }

  useEffect(() => {
    if (inView && !alreadyTrackedCarousel) {
      dispatch(
        trackEvent({
          category: Category.System,
          entity: Entity.Carousel,
          action: Action.Displayed,
          ...trackingPayload,
          payload: {
            ...(hotelId && {hotelId}),
            ...(placeId && {placeId}),
            totalNumber: searchHistory?.length,
            searchItem: pick(
              [...DESTINATION_KEYS, ...ITINERARY_KEYS],
              searchItem
            )
          },
          analyticsContext: {
            [AnalyticsContext.SearchContext]: searchContext
          }
        })
      )
      setAlreadyTrackedCarousel(true)
    }
  }, [
    inView,
    searchContext,
    dispatch,
    alreadyTrackedCarousel,
    setAlreadyTrackedCarousel
  ])

  if (!searchItem) {
    return null
  }

  return (
    <div ref={ref} className="chromatic-ignore">
      <TopSection
        className={cx(isMobile && '__mobile')}
        justifyContent="space-between"
        alignItems="center"
        gap="s200"
      >
        <TopSectionText>
          <Text variant="titleXL">
            <HighlightedTitle source="SuggestedHotels" />
          </Text>

          <Box
            display="flex"
            alignItems="center"
            gap="s200"
            marginBottom="s300"
            desktopMd={{marginBottom: 's500'}}
          >
            <Text colorPath="content.neutral.c600" variant="labelS">
              <FormattedDate
                value={dateStringToMiddayDate(checkIn)}
                month="short"
                day="2-digit"
              />
              {' - '}
              <FormattedDate
                value={dateStringToMiddayDate(checkOut)}
                month="short"
                day="2-digit"
              />
            </Text>
            <Text colorPath="content.neutral.c600" variant="bodyS">
              ·
            </Text>
            <Text colorPath="content.neutral.c600" variant="bodyS">
              <GuestAndRoomText rooms={rooms} />
            </Text>
          </Box>
        </TopSectionText>
        {!isMobile && (
          <Button variant="secondary" onClick={onSeeAllClick}>
            <SeeAllText>
              <FormattedMessageWrapper
                id="hotelCard.seeAll"
                defaultMessage="See all"
              />
            </SeeAllText>
          </Button>
        )}
      </TopSection>

      <SimilarHotels
        hotelId={hotelId}
        placeId={placeId}
        address={placeName}
        rooms={rooms}
        checkIn={checkIn}
        checkOut={checkOut}
        trackingPayload={trackingPayload}
        onHotelClick={onHotelClick}
        hasSidePaddings={isMobile}
        parentId="suggested-hotel"
        includeSameProperty
      />

      {isMobile && (
        <ContentWrapper paddingX="s500" paddingTop="s500">
          <Button variant="secondary" fullWidth onClick={onSeeAllClick}>
            <SeeAllText>
              <FormattedMessageWrapper
                id="hotelCard.seeAll"
                defaultMessage="See all"
              />
            </SeeAllText>
          </Button>
        </ContentWrapper>
      )}
    </div>
  )
}
