import {defineMessages} from 'react-intl'

export const guestPickerMessages = defineMessages({
  adult: {
    id: 'numberOfAdults',
    defaultMessage: '{count, plural, one {1 adult} other {# adults}}'
  },
  child: {
    id: 'numberOfChildren',
    defaultMessage: '{count, plural, one {1 child} other {# children}}'
  },
  childAgeWithNumber: {
    id: 'childAgeWithNumber',
    defaultMessage: 'Child {number} age'
  },
  guest: {
    id: 'numberOfGuests',
    defaultMessage: '{count, plural, one {1 guest} other {# guests}}'
  },
  guests: {
    id: 'guests',
    defaultMessage: 'Guests'
  },
  room: {
    id: 'numberOfRooms',
    defaultMessage: '{count, plural, one {1 room} other {# rooms}}'
  },
  rooms: {
    id: 'rooms',
    defaultMessage: 'Rooms'
  }
})
