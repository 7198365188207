import React from 'react'
import {defineMessage} from 'react-intl'
import {useSelector} from 'react-redux'
import {getCurrencyCode} from 'modules/meta/selectors'

import {Box} from '@daedalus/atlas/helpers/Box'
import {VioTagDetails} from '@daedalus/core/src/tag'
import {VioDealTag} from '@daedalus/shared/src/privateDeal/VioDealTag'

interface Props {
  vioTagDetails: VioTagDetails
}
export const RoomOfferVioTag: React.FC<Props> = ({vioTagDetails}) => {
  const currencyCode = useSelector(getCurrencyCode)
  const {savingType, savingPrice, savingPercentage} = vioTagDetails
  const message = defineMessage({
    id: 'vioLabelFinal',
    defaultMessage: 'Only on Vio'
  })
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      paddingBottom="s250"
    >
      <VioDealTag
        savingPercentage={savingPercentage}
        message={message}
        savingType={savingType}
        savingPrice={savingPrice}
        currencyCode={currencyCode}
      />
    </Box>
  )
}
