import React, {useCallback} from 'react'
import noop from 'lodash/noop'
import {toggle} from 'opticks'

import {FooterLinkButton} from '@daedalus/atlas/Footer/FooterLinkButton'
import {
  useIsCCPAState,
  useIsGDPRCountry
} from '@daedalus/core/src/_web/utils/cookies/hooks/cookieComplianceGeolocation'
import {useDispatchTeamEvent} from '@daedalus/core/src/analytics/hooks/useDispatchTeamEvent'
import {trackEvent} from '@daedalus/core/src/analytics/modules/actions'
import {TrackEventPayload} from '@daedalus/core/src/analytics/types/Events'
import {Brand} from '@daedalus/core/src/brand/types'
import {checkIsCookieConsentEnabledForBrand} from '@daedalus/core/src/brand/utils'
import FormattedMessageWrapper from '@daedalus/core/src/localization/components/FormattedMessage'

import {useCookieConsent} from './useCookieConsent'

interface Props {
  brand: Brand
}

export const CookiePreferencesLink = ({brand}: Props) => {
  const dispatchTeamEvent = useDispatchTeamEvent()
  const trackEventCallback = useCallback(
    (payload: TrackEventPayload) => dispatchTeamEvent(trackEvent(payload)),
    [dispatchTeamEvent]
  )

  const {openPreferencesCenter} = useCookieConsent(noop, trackEventCallback)

  const isGDPRCountry = useIsGDPRCountry()
  const isCCPAState = useIsCCPAState()

  const cookieConsentIsEnabled = checkIsCookieConsentEnabledForBrand(brand)

  const showCookiePreferencesLink =
    toggle(
      '0f0v1ebh-cookie-banner-on-non-gdpr-countries',
      isGDPRCountry || isCCPAState,
      true
    ) && cookieConsentIsEnabled

  return (
    showCookiePreferencesLink && (
      <li>
        <FooterLinkButton onClick={openPreferencesCenter}>
          <FormattedMessageWrapper
            dataId="CookiePreferences"
            id="cookies"
            defaultMessage="Cookies"
          />
        </FooterLinkButton>
      </li>
    )
  )
}
