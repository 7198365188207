import {defineMessages} from 'react-intl'

import {FacetGroupItem} from '../../Filters/FacetFilter'

export const HOTELS_TYPES = new Set([
  '0', // Hotel
  '1', // Motel
  '2', // Inn
  '3', // Bed & Breakfast
  '5', // Hostel
  '7', // Resort
  '11', // Ryokan
  '13', // Mini Hotel
  '15', // Farm Stay
  '28', // Capsule Hotel
  '31', // Eco Hotel
  '100', // Economy hotel
  '102', // Aparthotel
  '119', // Love hotels
  '122' // Boutique Hotel
])

const VACATION_RENTALS_TYPES = new Set([
  '4', // Holiday Home
  '6', // Retreat
  '8', // Other
  '9', // Apartment
  '10', // Pousada / Guest House
  '12', // Riad
  '16', // Guest House
  '17', // Chalet
  '18', // Fisherman's Cabin
  '19', // Cottage
  '20', // Villa
  '21', // Bungalow
  '22', // Dormitory
  '23', // Lodge
  '24', // Agriturismo / Farm Stay
  '25', // Chateau/Country House
  '27', // Luxury Tents/Yurts
  '29', // Holiday Park/Campsite
  '30', // Boat
  '32', // Homestay
  '33', // Serviced Apartment
  '34', // Pension
  '101', // All-inclusive property
  '103', // Cabin
  '104', // Campsite
  '105', // Castle
  '106', // Condo
  '107', // Condominium resort
  '108', // Cruise
  '109', // Palace
  '110', // Private vacation home
  '111', // Ranch
  '112', // Residence
  '113', // Safari/Tentalow
  '114', // TownHouse
  '115', // Tree house property
  '116', // Gites
  '117', // Guest accommodation
  '118', // Health resorts
  '120', // Student accommodation
  '121' // Caravan Park
])

export const PROPERTY_TYPE_LIMIT = 8

export const PROPERTY_GROUPS: FacetGroupItem[] = [
  {
    type: 'hotels',
    facetIds: HOTELS_TYPES,
    header: {
      ...defineMessages({
        title: {
          id: 'propertyType.hotelsTitle',
          defaultMessage: 'Hotels'
        },
        description: {
          id: 'propertyType.hotelsSubtitle',
          defaultMessage: 'Includes hotels, hostels, resorts and more'
        }
      }),
      groupIcon: 'Building'
    }
  },
  {
    type: 'vacationRentals',
    facetIds: VACATION_RENTALS_TYPES,
    header: {
      ...defineMessages({
        title: {
          id: 'propertyType.vacationRentalsTitle',
          defaultMessage: 'Vacation rentals'
        },
        description: {
          id: 'propertyType.vacationRentalsSubtitle',
          defaultMessage:
            'Includes apartments, holiday homes, cottages and more'
        }
      }),
      groupIcon: 'House'
    }
  }
]
