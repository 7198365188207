import {defineMessages} from 'react-intl'

export const toastMessages = defineMessages({
  pictureChanged: {
    id: 'pictureChanged',
    defaultMessage: 'Your photo has updated'
  },
  emailAddressInUserError: {
    id: 'emailAddressInUserError',
    defaultMessage: 'This email has already been registered'
  },
  userUpdateError: {
    id: 'userUpdateError',
    defaultMessage: 'Update error, please check the personal details'
  },
  failedToSaveS3: {
    id: 'imageSaveFailed',
    defaultMessage: 'Updating your photo has been failed, please try again.'
  },
  noImageToSave: {
    id: 'noImageToSave',
    defaultMessage: 'Please select an image'
  },
  unknownFileType: {
    id: 'unknownFileType',
    defaultMessage: 'Wrong file type. Please select a JPG or PNG file.'
  },
  reSendEmailValidationFailed: {
    id: 'reSendEmailValidationFailed',
    defaultMessage: 'Resend email validation has been failed. Please try again.'
  },
  imageSizeError: {
    id: 'imageSizeIsBig',
    defaultMessage: 'Max image size is 3 MB. Please select a smaller image.'
  },
  userDataSaved: {
    id: 'changesSaved',
    defaultMessage: 'Your changes have been saved'
  },
  userDataSaveError: {
    id: 'saveFailed',
    defaultMessage: "Sorry, we couldn't save your changes. Please try again."
  }
})
