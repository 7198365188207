import {defineMessages} from 'react-intl'
import {toggle} from 'opticks'

import {SearchOffer} from '../types/SearchOffer'
import {getHasFreeCancellation} from './cancellationPenalties'
import {getMealsAmenities, getPrioritizedMeals} from './meal'

/**
 * Translation dictionary containing labels for each possible room label, including the id and defaultMessage.
 */
export const roomLabels = defineMessages({
  freeCancellation: {
    id: 'cancellationPenalties.freeCancellation',
    defaultMessage: 'Free cancellation'
  },
  freeCancellationDeadline: {
    id: 'hotelOffer.freeCancellationDeadline',
    defaultMessage: 'Free cancellation before {date}'
  },
  payLater: {
    id: 'paymentSchedule.payLater',
    defaultMessage: 'Pay later'
  },
  allInclusive: {
    id: 'mealType.allInclusive',
    defaultMessage: 'All inclusive'
  },
  breakfast: {
    id: 'mealType.breakfastIncluded',
    defaultMessage: 'Breakfast included'
  },
  lunch: {
    id: 'hotelOffer.lunch',
    defaultMessage: 'Lunch included'
  },
  dinner: {
    id: 'hotelOffer.dinner',
    defaultMessage: 'Dinner included'
  },
  fullBoard: {
    id: 'hotelOffer.fullBoard',
    defaultMessage: 'Full board meal'
  },
  halfBoard: {
    id: 'hotelOffer.halfBoard',
    defaultMessage: 'Half board meal'
  },
  meals: {
    id: 'mealType.allInclusive',
    defaultMessage: 'All inclusive'
  },
  yourChoice: {
    id: 'yourChoice',
    defaultMessage: 'Your Choice'
  },
  bookNowPayLater: {
    id: 'payment.payLater',
    defaultMessage: 'Book now, pay later'
  },
  chargeLater: {
    id: 'chargeLater.label',
    defaultMessage: 'Pay now or later'
  }
})

export interface GetRoomLabelsProps {
  // Whether the booking is split.
  isSplitBooking?: boolean
  // The offer to get the labels for.
  offer: SearchOffer
  // Whether to show the free cancellation date.
  shouldShowFreeCancellationDate?: boolean
  // Whether to show the "Your Choice" label.
  shouldShowYourChoice: boolean
}

const getFreeCancellationLabel = ({
  offer,
  shouldShowFreeCancellationDate
}: {
  offer: GetRoomLabelsProps['offer']
  shouldShowFreeCancellationDate: GetRoomLabelsProps['shouldShowFreeCancellationDate']
}) => {
  const hasFreeCancellation = getHasFreeCancellation(
    offer?.cancellationPenalties
  )

  if (hasFreeCancellation && shouldShowFreeCancellationDate) {
    return [roomLabels.freeCancellationDeadline]
  }

  if (hasFreeCancellation) {
    return [roomLabels.freeCancellation]
  }

  return []
}

const getChargeLaterLabel = ({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  offer,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  isSplitBooking
}: {
  offer: GetRoomLabelsProps['offer']
  isSplitBooking: GetRoomLabelsProps['isSplitBooking']
}) => {
  const isChargeLaterEligible = false

  const bookNowPayLaterMessage = toggle(
    '50feb427-cl-differential-price',
    roomLabels.bookNowPayLater,
    roomLabels.chargeLater
  )

  return isChargeLaterEligible ? [bookNowPayLaterMessage] : []
}

const getMealsLabels = (offer: GetRoomLabelsProps['offer']) => {
  const meals = getMealsAmenities(offer)

  return getPrioritizedMeals(meals).map(meal => {
    return meal && roomLabels[meal]
  })
}

const getMessageObjects = ({
  offer,
  isSplitBooking = false,
  shouldShowYourChoice,
  shouldShowFreeCancellationDate
}: GetRoomLabelsProps) => {
  const yourChoiceLabel = shouldShowYourChoice ? [roomLabels.yourChoice] : []

  const freeCancellationLabel = getFreeCancellationLabel({
    offer,
    shouldShowFreeCancellationDate
  })

  const payLaterLabel = offer?.package?.canPayLater ? [roomLabels.payLater] : []

  const chargeLaterLabel = getChargeLaterLabel({
    offer,
    isSplitBooking
  })

  const translatedMealsLabels = getMealsLabels(offer)

  return [
    ...yourChoiceLabel,
    ...freeCancellationLabel,
    ...payLaterLabel,
    ...chargeLaterLabel,
    ...translatedMealsLabels
  ]
}

/**
 * Get the room labels for a given offer.
 * @param  GetRoomLabelsProps - The properties to get the room labels for.
 * @param GetRoomLabelsProps.offer - The offer object containing room details.
 * @param  GetRoomLabelsProps.shouldShowFreeCancellationDate - Whether to display the free cancellation date.
 * @param  GetRoomLabelsProps.isSplitBooking - Whether the booking is split across multiple reservations.
 * @param  GetRoomLabelsProps.shouldShowYourChoice - Whether to highlight the user's choice.
 * @returns An array containing labels for each room offer, including the id and defaultMessage.
 */
export const getRoomLabels = ({
  offer,
  shouldShowFreeCancellationDate = false,
  isSplitBooking = false,
  shouldShowYourChoice
}: GetRoomLabelsProps) => {
  const labelMessagesList = getMessageObjects({
    offer,
    shouldShowFreeCancellationDate,
    isSplitBooking,
    shouldShowYourChoice
  })

  return labelMessagesList
}
