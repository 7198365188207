import React from 'react'
import {defineMessage} from 'react-intl'
import {useSelector} from 'react-redux'
import {getCurrencyCode} from 'modules/meta/selectors'
import {getShowTotalPrices} from 'modules/sapiSearch/selectors'

import {useBrand} from '@daedalus/core/src/_web/brand/hooks/useBrand'
import getIsBofhOffer from '@daedalus/core/src/offer/business/isBofhOffer'
import {SearchOffer} from '@daedalus/core/src/offer/types/SearchOffer'
import {useVioTag} from '@daedalus/core/src/tag'
import {VioDealTag} from '@daedalus/shared/src/privateDeal/VioDealTag'

interface Props {
  anchorPrice?: number
  anchorPriceTotal?: number
  offer: SearchOffer
}

export const VioDealTagContainer: React.FC<Props> = ({
  anchorPrice,
  anchorPriceTotal,
  offer
}) => {
  const {brandCode} = useBrand()
  const currencyCode = useSelector(getCurrencyCode)
  const showTotalPrices = useSelector(getShowTotalPrices)

  const isBofhOffer = getIsBofhOffer(offer)
  const {isVioTagVisible, savingType, savingPrice, savingPercentage} =
    useVioTag({
      price: showTotalPrices ? offer.totalRate : offer.nightlyRate,
      anchorPrice: showTotalPrices ? anchorPriceTotal : anchorPrice,
      currencyCode,
      isVioOffer: isBofhOffer,
      brandCode
    })

  if (!isVioTagVisible) return null
  const message = defineMessage({
    id: 'vioLabelFinal',
    defaultMessage: 'Only on Vio'
  })
  return (
    <VioDealTag
      message={message}
      savingPercentage={savingPercentage}
      savingPrice={savingPrice}
      currencyCode={currencyCode}
      savingType={savingType}
    />
  )
}
