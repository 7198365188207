import React from 'react'
import {useSelector} from 'react-redux'
import {getAnchorHotel} from 'modules/sapiSearch/selectors'

import {PopUp} from '@daedalus/atlas/PopUp'
import {FormattedMessageWrapper} from '@daedalus/core/src/localization/components/FormattedMessage'

import {AvailabilityPopupContent} from './AvailabilityPopupContent'

interface Props {
  isOpen: boolean
  onClose: () => void
}

export const AvailabilityPopup = ({isOpen, onClose}: Props) => {
  const hotel = useSelector(getAnchorHotel)
  return (
    <PopUp
      isOpen={isOpen}
      closeAction={onClose}
      dataId="AvailabilityPopup"
      width={802}
      noPaddings
      titleBarProps={{
        centerContent: (
          <FormattedMessageWrapper
            id="unavailabilityPopupTitle"
            defaultMessage="Select alternative dates for {hotelName}"
            values={{hotelName: hotel?.hotelName}}
          />
        ),
        hasDivider: true
      }}
    >
      {hotel && (
        <>
          <AvailabilityPopupContent />
        </>
      )}
    </PopUp>
  )
}
