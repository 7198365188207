import {defineMessages} from 'react-intl'

export const searchErrorMessages = defineMessages({
  crashPageMessageTitle: {
    id: 'crashPage.messageTitle',
    defaultMessage: 'Uh oh, something went wrong'
  },
  crashPageMessageBody: {
    id: 'crashPage.messageBody',
    defaultMessage:
      "Our developers have been notified. But don't worry, we'll get you back to finding the best deals."
  },
  crashPageButtonRefresh: {
    id: 'crashPage.buttonRefresh',
    defaultMessage: 'Refresh'
  }
})
