import {defineMessages} from 'react-intl'

/**
 * Defines the review sentiment messages
 * @returns The review sentiment messages
 */
export const reviewSentimentMessages = defineMessages({
  exceptional: {
    id: 'reviewSentiment.exceptional',
    defaultMessage: 'Exceptional'
  },
  superb: {
    id: 'reviewSentiment.superb',
    defaultMessage: 'Superb'
  },
  fabulous: {
    id: 'reviewSentiment.fabulous',
    defaultMessage: 'Fabulous'
  },
  veryGood: {
    id: 'reviewSentiment.veryGood',
    defaultMessage: 'Very good'
  },
  good: {
    id: 'reviewSentiment.good',
    defaultMessage: 'Good'
  },
  pleasant: {
    id: 'reviewSentiment.pleasant',
    defaultMessage: 'Pleasant'
  },
  noRating: {
    id: 'reviewSentiment.noRating',
    defaultMessage: 'No rating'
  }
})
