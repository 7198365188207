import React, {useContext, useRef} from 'react'
import {useIntl} from 'react-intl'
import {useSelector} from 'react-redux'
import {
  Legend,
  LegendBoxGreen,
  LegendBoxRed,
  OffersWrapper
} from 'components/AvailabilityDatePicker/AvailabilityHelpers'
import {TaxationAndNightlyPriceMessage} from 'components/TaxationAndNightlyPriceMessage'
import {
  getHotel,
  getHotelAvailabilityPricesComplete,
  getShowTotalPrices
} from 'modules/sapiSearch/selectors'

import ContentWrapper from '@daedalus/atlas/helpers/ContentWrapper'
import {Grid} from '@daedalus/atlas/helpers/Grid'
import {Icon} from '@daedalus/atlas/Icon'
import {OverlayPage, OverlayPageContent} from '@daedalus/atlas/OverlayPage'
import {Text} from '@daedalus/atlas/Text'
import {AvailabilityHotelEntity} from '@daedalus/core/src/availability/types'
import {ConfigureI18nContext} from '@daedalus/core/src/localization/components/ConfigureI18n'
import {FormattedMessageWrapper} from '@daedalus/core/src/localization/components/FormattedMessage'
import {SearchOffer} from '@daedalus/core/src/offer/types/SearchOffer'
import {getCheckOutDate} from '@daedalus/core/src/searchParams/validateSearchParamDates'
import {dateStringToMiddayDate} from '@daedalus/core/src/utils/date'
import {AvailabilityDatePicker} from '@daedalus/shared/src/search/DatePicker/AvailabilityDatePicker'
import {WeekDays} from '@daedalus/shared/src/search/DatePickerOverlay/WeekDays'

import {OffersList} from '../../AvailabilityDatePicker/OffersList'

interface Props {
  /** Hotel ID */
  hotelId: string
  /** Selected date */
  selectedDate: string | null | undefined
  /** Date of Check In */
  checkIn: string
  /** Whether the overlay is open */
  isOpen: boolean
  /** The day click handler */
  onDayClick: (date: string) => void
  /** The callback for closing the overlay  */
  onClose: () => void
  /** Earliest Date that the user can select on the date picker for checkin */
  earliestCheckInDate?: Date
  /** Offers data to be displayed in the overlay */
  offersData?: SearchOffer[]
  /** Number of nights stay */
  nights: number
  /** Hotel availability data */
  hotelAvailabilityPrices: AvailabilityHotelEntity
  /** The callback for when the month changes */
  onMonthChange: (date: Date) => void
  /** The source component name for tracking */
  sourceComponent: string
}

export const DatePickerOverlay = ({
  hotelId,
  selectedDate,
  checkIn,
  isOpen,
  onDayClick,
  onClose,
  earliestCheckInDate,
  offersData,
  nights,
  hotelAvailabilityPrices,
  onMonthChange,
  sourceComponent
}: Props) => {
  const {formatDate} = useIntl()
  const containerRef = useRef<null | HTMLDivElement>(null)
  const searchComplete = useSelector(getHotelAvailabilityPricesComplete)
  const {monthNames, weekDayNamesShort, firstDayOfWeek} =
    useContext(ConfigureI18nContext)
  const hotelDetails = useSelector(state => getHotel(state, hotelId))
  const showTotalPrices = useSelector(getShowTotalPrices)

  return (
    <OverlayPage
      isOpen={isOpen}
      titleBarProps={{
        centerContent: hotelDetails?.hotelName
      }}
      subHeaderContent={
        <WeekDays
          firstDayOfWeek={firstDayOfWeek}
          weekdaysShort={weekDayNamesShort}
          shouldHighlightWeekend
        />
      }
      onClose={onClose}
      dataId="AvailabilityPopup"
    >
      <OverlayPageContent ref={containerRef}>
        <>
          <AvailabilityDatePicker
            numberOfMonthsToShow={1}
            firstDayOfWeek={firstDayOfWeek}
            weekdaysShort={weekDayNamesShort}
            selectedDate={selectedDate}
            months={monthNames}
            variant="horizontal"
            earliestCheckInDate={earliestCheckInDate}
            onDayClick={onDayClick}
            hotelAvailabilityPrices={hotelAvailabilityPrices}
            checkIn={checkIn}
            onMonthChange={onMonthChange}
            searchComplete={searchComplete}
            showTotalPrices={showTotalPrices}
          />

          <ContentWrapper paddingBottom="s500" paddingX="s500">
            <Text
              variant="bodyXS"
              colorPath="content.neutral.c600"
              align="right"
            >
              {showTotalPrices ? (
                <>
                  <FormattedMessageWrapper
                    id="payment.total"
                    defaultMessage="total"
                  />
                  &nbsp;
                  <FormattedMessageWrapper
                    id="includingTaxesShort"
                    defaultMessage="incl tax & fees"
                  />
                </>
              ) : (
                <TaxationAndNightlyPriceMessage
                  showCurrencyCode
                  hotelId={hotelId}
                />
              )}
            </Text>

            <Legend>
              <Grid container spacing="s200" justify="flex-end">
                <Grid mobileSm="fit">
                  <LegendBoxGreen />
                </Grid>
                <Grid mobileSm="fit">
                  <Text variant="bodyXS" colorPath="content.neutral.c600">
                    <FormattedMessageWrapper
                      id="cheaper"
                      defaultMessage="Cheaper"
                    />
                  </Text>
                </Grid>
                <Grid mobileSm="fit">
                  <LegendBoxRed />
                </Grid>
                <Grid mobileSm="fit">
                  <Text variant="bodyXS" colorPath="content.neutral.c600">
                    <FormattedMessageWrapper
                      id="Higher"
                      defaultMessage="Higher"
                    />
                  </Text>
                </Grid>
              </Grid>
            </Legend>

            <ContentWrapper paddingTop="s500">
              {!offersData && selectedDate && searchComplete && (
                <Text variant="titleL">
                  <Grid container spacing="s200" alignItems="center">
                    <Grid mobileSm="fit">
                      <Icon
                        size="md"
                        name="Forbid"
                        colorPath="content.danger.c500"
                      />
                    </Grid>
                    <Grid mobileSm="fit">
                      <FormattedMessageWrapper
                        id="soldOut.messageWithDatesB"
                        defaultMessage="Sold out {checkIn} - {checkOut}"
                        values={{
                          checkIn: formatDate(
                            dateStringToMiddayDate(selectedDate),
                            {
                              day: '2-digit',
                              month: 'short'
                            }
                          ),
                          checkOut: formatDate(
                            getCheckOutDate(selectedDate, nights),
                            {
                              day: '2-digit',
                              month: 'short'
                            }
                          )
                        }}
                      />
                    </Grid>
                  </Grid>
                </Text>
              )}

              {offersData && searchComplete && (
                <OffersWrapper>
                  <OffersList
                    hotelId={hotelId}
                    offers={offersData}
                    sourceComponent={sourceComponent}
                  />
                </OffersWrapper>
              )}
            </ContentWrapper>
          </ContentWrapper>
        </>
      </OverlayPageContent>
    </OverlayPage>
  )
}
