import {createSlice} from '@reduxjs/toolkit'

import {SourceComponentType} from '@daedalus/core/src/auth/types/Auth'

import type {PayloadAction} from '@reduxjs/toolkit'

interface EmailSignUpState {
  isOpen: boolean
  isEmailVerified: boolean
  sourceComponent?: SourceComponentType | null
}

const initialState = {
  isOpen: false,
  sourceComponent: null
} as EmailSignUpState

const {actions, reducer} = createSlice({
  name: 'emailSignUp',
  initialState,
  reducers: {
    setEmailSignUpVisibility(
      state,
      action: PayloadAction<{
        isOpen: boolean
        sourceComponent?: SourceComponentType | null
      }>
    ) {
      state.isOpen = action.payload.isOpen
      state.sourceComponent = action.payload?.sourceComponent
    }
  }
})

export default reducer

export const {setEmailSignUpVisibility} = actions
