import React, {useContext} from 'react'
import {useDispatch, useSelector} from 'react-redux'
import {HotelCardContext} from 'components/HotelCardContext'
import {useTrackOfferView} from 'hooks/analytics'
import {getOfferContext} from 'middleware/analytics/selectors'
import {getCurrencyCode, getLocaleCode} from 'modules/meta/selectors'
import {showWeb2AppPopupOverlay} from 'modules/overlay/actions'
import {
  getAppLockedDealConfig,
  getNumberOfRooms,
  getShowTotalPrices
} from 'modules/sapiSearch/selectors'
import {SearchOffer} from 'modules/sapiSearch/slice'
import {getIsMultiRoomBundlesSearch} from 'modules/search/selectors'

import {Button} from '@daedalus/atlas/Button'
import {Box} from '@daedalus/atlas/helpers/Box'
import {Icon} from '@daedalus/atlas/Icon'
import {Text} from '@daedalus/atlas/Text'
import {persistAppLockedDealImpressionsCount} from '@daedalus/core/src/_web/appLockedDeal/impressionsCount'
import {trackEvent} from '@daedalus/core/src/analytics/modules/actions'
import {
  Action,
  AnalyticsContext,
  Category,
  Entity
} from '@daedalus/core/src/analytics/types/Events'
import {Placement} from '@daedalus/core/src/deepLinking/types'
import FormattedMessageWrapper from '@daedalus/core/src/localization/components/FormattedMessage'
import {formatPriceValue} from '@daedalus/core/src/price/business/formatPrice'
import {getNightlyPriceForAllRooms} from '@daedalus/core/src/price/business/price'
import {getRoundingStrategy} from '@daedalus/core/src/utils/number'

interface Props {
  offer: SearchOffer
}

export const DesktopWeb2AppCta: React.FC<Props> = ({offer}) => {
  const dispatch = useDispatch()
  const {hotelId, isAnchor} = useContext(HotelCardContext)
  const locale = useSelector(getLocaleCode)
  const currency = useSelector(getCurrencyCode)
  const showTotalPrices = useSelector(getShowTotalPrices)
  const numberOfRooms = useSelector(state => getNumberOfRooms(state, hotelId))
  const isMultiRoomBundlesSearch = useSelector(getIsMultiRoomBundlesSearch)

  const bannerId: Placement = isAnchor
    ? Placement.WebToAppDesktopAnchor
    : Placement.WebToAppDesktopNonAnchor
  const inViewRef = useTrackOfferView(
    offer,
    hotelId,
    'Web2AppPromoCta',
    bannerId
  )

  const {nightlyRate, totalRate} = offer

  const calculatedNightlyPrice = getNightlyPriceForAllRooms(
    nightlyRate,
    isMultiRoomBundlesSearch,
    numberOfRooms
  )

  const price = formatPriceValue({
    locale,
    value: showTotalPrices ? totalRate : calculatedNightlyPrice,
    currency,
    roundingStrategy: getRoundingStrategy(),
    maximumFractionDigits: 0
  })

  // Analytics
  const {isAppLockedDeal} = useSelector(state =>
    getAppLockedDealConfig(state, hotelId, offer?.id)
  )

  const isAnchorAppLockedDeal = isAnchor && isAppLockedDeal

  const impressionDayCount = persistAppLockedDealImpressionsCount(
    isAnchorAppLockedDeal
  )
  const offerContext = useSelector(state =>
    getOfferContext(state, hotelId, offer?.id, '')
  )

  const handleClick = () => {
    dispatch(
      trackEvent({
        category: Category.User,
        entity: Entity.AppExclusiveOffer,
        action: Action.Clicked,
        payload: {
          bannerId,
          impressionDayCount
        },
        analyticsContext: {
          [AnalyticsContext.OfferContext]: offerContext
        }
      })
    )

    dispatch(
      showWeb2AppPopupOverlay({
        hotelId: hotelId,
        placement: bannerId
      })
    )
  }

  return (
    <Box marginTop="s300">
      <Button
        dataId="DesktopWeb2AppCta"
        fullWidth
        variant="special"
        iconStart={
          <Box hasMirror>
            <Icon name="Iphone" />
          </Box>
        }
        iconEnd={
          <Box hasMirror>
            <Icon name="ChevronRight" />
          </Box>
        }
        onClick={handleClick}
        ref={inViewRef}
      >
        <Text variant="labelM" align="left">
          <FormattedMessageWrapper
            id="web2appPopup.appPrice"
            defaultMessage="{price} on Vio app"
            values={{
              price
            }}
          />
        </Text>
      </Button>
    </Box>
  )
}
