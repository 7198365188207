import {useCallback, useEffect, useState} from 'react'
import {defineMessages, useIntl} from 'react-intl'
import {useDispatch, useSelector} from 'react-redux'
import {useUrlUpdater} from 'components/data/WithUrlUpdater'
import {filterMessages} from 'components/Filters/filterMessages'
import {getSapiFiltersFromDaedalusSearchParameters} from 'modules/sapiSearch/utils'
import {getUrlParams} from 'modules/search/selectors'
import {FilterUrlParams} from 'modules/search/types'
import {setSearchTrigger} from 'modules/searchBox/slice'
import {SearchTrigger} from 'modules/searchBox/types'
import {isEmpty, isNil, keys, omit, pick, pickAll, pickBy, sort} from 'ramda'
import {
  forceRemoveMultiFilters,
  forceSetMultiFilters,
  toggleMultiFilter
} from 'utils/filters'

import {trackEvent} from '@daedalus/core/src/analytics/modules/actions'
import {
  Action,
  Category,
  Entity
} from '@daedalus/core/src/analytics/types/Events'
import {
  calculateAscendingNumberArray,
  isSequential
} from '@daedalus/core/src/utils/array'

const initialFiltersState: FilterUrlParams = {
  facilities: null,
  freeCancellation: null,
  reviewScore: null,
  hotelName: null,
  propertyTypes: null,
  starRatings: null,
  themes: null,
  priceMin: null,
  priceMax: null,
  sortField: null,
  sortOrder: null,
  amenities: null
}

export const emptyFilterState: FilterUrlParams = {
  facilities: undefined,
  freeCancellation: undefined,
  reviewScore: undefined,
  hotelName: undefined,
  propertyTypes: undefined,
  starRatings: undefined,
  themes: undefined,
  priceMin: undefined,
  priceMax: undefined,
  sortField: undefined,
  sortOrder: undefined,
  amenities: undefined
}

const allFilterKeys = keys(initialFiltersState)

/**
 * Creates a local state for the specified `filterKeys`. The local state filters are initialized to the values stored in the global state, or the filters' initial (empty) state.
 * The local state updates along with the user's changes. When the filters are submitted, the global state is updated.
 *
 * @param filterKeys - The filter keys that will be used in this instance of the hook
 * @param elementName - The name of the element that uses this instance of the hook (used for tracking). Falls back to the first `filterKeys` value if no value is specified.
 * @returns A set of functions for setting, clearing and submitting the filters from the local state
 */
export const useFiltersForm = (
  filterKeys: string[],
  elementName = filterKeys[0]
) => {
  const dispatch = useDispatch()
  const {formatMessage} = useIntl()

  const {updateLocation} = useUrlUpdater()
  const searchParams = useSelector(getUrlParams)

  const [filters, setFilters] = useState<FilterUrlParams>(
    pick(filterKeys, initialFiltersState)
  )

  /**
   * Gets the applied filters from the global state
   *
   * @param keys - The filter keys to retrieve from the global state. Uses the hook's `filterKeys` value by default.
   * @returns A key-value pair of applied filters from the global state
   */
  const getAppliedFilters = useCallback(
    (keys = filterKeys): FilterUrlParams => pickAll(keys, searchParams),
    [searchParams, filterKeys]
  )

  useEffect(() => {
    const appliedFilters = getAppliedFilters()
    if (!isNil(appliedFilters)) {
      setFilters(appliedFilters)
    }
  }, [searchParams, getAppliedFilters])

  const calculateIfFiltersChanged = useCallback(
    (filters: FilterUrlParams) => {
      const searchParamFilters = getAppliedFilters()

      return JSON.stringify(filters) !== JSON.stringify(searchParamFilters)
    },
    [getAppliedFilters]
  )

  const trackFilterSubmit = useCallback(
    (updatedFilters: FilterUrlParams) => {
      const newSelectedFilters =
        getSapiFiltersFromDaedalusSearchParameters(updatedFilters)
      const allSelectedFilters = getSapiFiltersFromDaedalusSearchParameters({
        ...getAppliedFilters(allFilterKeys),
        ...updatedFilters
      })

      dispatch(
        trackEvent({
          category: Category.User,
          entity: Entity.FilterPreference,
          action: Action.Submitted,
          payload: {
            newSelectedFilters,
            allSelectedFilters,
            appliedFiltersFrom: elementName
          }
        })
      )
    },
    [dispatch, getAppliedFilters, elementName]
  )

  const updateSearchParamFilters = useCallback(
    (filters: FilterUrlParams) => {
      updateLocation({
        ...filters,
        userInteractedWithFilters: '1',
        userSearch: '1'
      })
      dispatch(setSearchTrigger(SearchTrigger.Filter))
      trackFilterSubmit(filters)
    },
    [updateLocation, trackFilterSubmit]
  )

  /**
   * Gets the selected filters, based on the hook's `filterKeys` value, from the hook's local state
   *
   * @returns A key-value pair of filters from the local state
   */
  const getFilters = useCallback(
    (): FilterUrlParams | null => pick(filterKeys, filters),
    [filterKeys, filters]
  )

  /**
   * Add the selected values in the hook's local state for a filter that has multiple boolean values
   *
   * @param filterKey - The key of the filter to be updated
   * @param filterValue - The filter value that should be toggled
   */
  const handleMultiValueChange = useCallback(
    (
      filterKey: keyof FilterUrlParams,
      filterValue: string | null | undefined
    ) => {
      setFilters(filters => ({
        ...filters,
        [filterKey]: toggleMultiFilter(filters[filterKey], filterValue)
      }))
    },
    []
  )

  /**
   * Add the selected values in the hook's local state for a filter
   *
   * @param filterKey - The key of the filter to be updated
   * @param filterValues - The filter values that should be set
   */
  const handleSetMultiValues = useCallback(
    (filterKey: keyof FilterUrlParams, filterValues: (string | number)[]) =>
      setFilters(filters => ({
        ...filters,
        [filterKey]: forceSetMultiFilters(filters[filterKey], filterValues)
      })),
    []
  )

  /**
   * Remove the selected values in the hook's local state for a filter
   *
   * @param filterKey - The key of the filter to be updated
   * @param filterValues - The filter values that should be removed
   */
  const handleRemoveMultiValues = useCallback(
    (filterKey: keyof FilterUrlParams, filterValues: (string | number)[]) =>
      setFilters(filters => ({
        ...filters,
        [filterKey]: forceRemoveMultiFilters(filters[filterKey], filterValues)
      })),
    []
  )

  /**
   * Changes the selected values in the hook's local state based on the passed in key-value pair
   *
   * @param updatedFilters - A key-value pair of filters and their new values
   */
  const handleValuesChange = useCallback(
    (updatedFilters: FilterUrlParams) =>
      setFilters(filters => ({
        ...filters,
        ...updatedFilters
      })),
    []
  )

  /**
   * Submits the specified filters and commits them to the global state if they have changed
   *
   * @param updatedFilters - A key-value pair of filters and their new values to be commited to the global state. Uses the selected values from the hook's local state by default.
   */
  const submitFilters = useCallback(
    (updatedFilters = filters) => {
      const filtersHaveChanged = calculateIfFiltersChanged(updatedFilters)

      if (filtersHaveChanged) {
        updateSearchParamFilters(updatedFilters)
      }
    },
    [filters, calculateIfFiltersChanged, updateSearchParamFilters]
  )

  /**
   * Resets the selected filter values in the hook's local state to the applied filters from the global state.
   * If no applied filters exist in the global state, reset the filters to their initial (empty) state.
   */
  const resetFilters = useCallback(() => {
    const searchParamFilters = getAppliedFilters()
    const filters = isNil(searchParamFilters)
      ? initialFiltersState
      : searchParamFilters
    setFilters(filters)
  }, [getAppliedFilters])

  /**
   * Clears the applied `filters` based on the hook's `filterKeys` from the global state and does not trigger the search nor the changes the url params.
   */
  const clearFilters = useCallback(() => {
    const emptyFilters = pickAll(filterKeys, {})
    setFilters(emptyFilters)
  }, [filterKeys])

  /**
   * Clears the applied `filters` based on the hook's `filterKeys` from the global state and sets them to their initial (empty) state. Also triggers the search and changes the url params.
   */
  const clearFiltersAndApply = useCallback(() => {
    const emptyFilters = pickAll(filterKeys, {})

    const filtersHaveChanged = calculateIfFiltersChanged(emptyFilters)

    if (filtersHaveChanged) {
      updateSearchParamFilters(emptyFilters)
    } else {
      resetFilters()
    }
  }, [
    filterKeys,
    calculateIfFiltersChanged,
    updateSearchParamFilters,
    resetFilters
  ])

  /**
   * Fires a clicked tracking event based on based on the hook's `elementName` value
   */
  const trackFilterClick = useCallback(
    (eventPayload = {}) => {
      dispatch(
        trackEvent({
          category: Category.User,
          entity: Entity.FilterBarFilterButton,
          action: Action.Clicked,
          payload: {
            selectedElement: elementName
          },
          ...eventPayload
        })
      )
    },
    [dispatch, elementName]
  )

  const countFilters = useCallback((filters: FilterUrlParams) => {
    // filter out priceMax, sortOrder as these will cause an extra value
    const cleanedFilters = omit(['priceMax', 'sortOrder'], filters)

    // remove all undefined filters
    const existingFilters = pickBy(
      val => val !== undefined && val !== null,
      cleanedFilters
    )

    if (!isEmpty(existingFilters)) {
      let count = 0
      Object.values(existingFilters).forEach(filterVal => {
        if (typeof filterVal === 'string' || typeof filterVal === 'number') {
          count++
        } else {
          count += filterVal.length
        }
      })
      return count
    }

    return 0
  }, [])

  /**
   * Gets the total count of applied filters
   */
  const getAppliedFiltersCount = useCallback(
    () => countFilters(getAppliedFilters()),
    [getAppliedFilters, countFilters]
  )

  /**
   * Gets the total count of filters before being applied
   */
  const getFiltersCount = useCallback(
    () => countFilters(getFilters()),
    [getFilters, countFilters]
  )

  /**
   * getAppliedStarsLabel: Generates a user-friendly label based on the selected star ratings.
   *
   * @param string || array [appliedStarRatingFilter=getAppliedFilters().starRatings] - The selected star ratings, either as a string (for single rating) or array (for multiple ratings).
   *
   * @returns string - Formatted label string based on the star ratings.
   *
   * @example
   * 1. If no star rating: Returns default message for hotel star class.
   * 2. Single star rating: Returns singular/plural message based on the rating, e.g., "4-stars".
   * 3. Multiple, sequential ratings: Returns a range, e.g., "3 to 5 stars".
   * 4. Non-sequential multiple ratings: Lists each rating, e.g., "3, 4, or 5 stars".
   */
  const getAppliedStarsLabel = useCallback(
    (appliedStarRatingFilter = getAppliedFilters().starRatings) => {
      const starRatingsMessages = defineMessages({
        starRatingOr: {
          id: 'filters.starRatingOr',
          defaultMessage: 'or {starRating}'
        }
      })

      if (!appliedStarRatingFilter)
        return formatMessage(filterMessages.hotelStarClass)

      if (appliedStarRatingFilter.length === 1) {
        return formatMessage(
          {
            id: 'filters.starRating',
            defaultMessage: '{starRating, plural, one {1 star} other {# stars}}'
          },
          {starRating: appliedStarRatingFilter[0]}
        )
      }

      const sortedRatings = sort(
        calculateAscendingNumberArray,
        appliedStarRatingFilter as unknown as number[]
      )

      if (isSequential(sortedRatings)) {
        return formatMessage(
          {
            id: 'filters.starRatingSelectedTo',
            defaultMessage: '{starRating1} to {starRating2}-star'
          },
          {
            starRating1: sortedRatings[0],
            starRating2: sortedRatings[sortedRatings.length - 1]
          }
        )
      }

      const ratingsText = sortedRatings.reduce((text, rating, index) => {
        if (index === sortedRatings.length - 1) {
          const ratingText = formatMessage(starRatingsMessages.starRatingOr, {
            starRating: formatMessage(
              {
                id: 'filters.starRating',
                defaultMessage:
                  '{starRating, plural, one {1 star} other {# stars}}'
              },
              {
                starRating: rating
              }
            )
          })
          return `${text} ${ratingText}`
        }
        const ratingText =
          index === sortedRatings.length - 2 ? rating : `${rating}, `
        return text + ratingText
      }, '')

      return ratingsText
    },
    [getAppliedFilters, formatMessage]
  )

  return {
    getAppliedFilters,
    getFilters,
    toggleMultiFilter: handleMultiValueChange,
    setMultiFilters: handleSetMultiValues,
    removeMultiFilters: handleRemoveMultiValues,
    toggleFilters: handleValuesChange,
    submitFilters,
    resetFilters,
    clearFilters,
    trackFilterClick,
    getAppliedFiltersCount,
    getFiltersCount,
    calculateIfFiltersChanged,
    getAppliedStarsLabel,
    clearFiltersAndApply
  }
}
