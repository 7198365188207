// TODO (Core): Add unit tests for these functions
import {parseUrl} from '../../_web/utils/url'
import {getIsProductionAppEnv} from '../../utils/environment'
import {brands} from '../config'
import {Brand, BrandCode} from '../types'

/**
 * Checks whether the passed-in brand has Cookie Consent enabled
 * @param brand - The brand object to check for Cookie Consent
 * @returns True if the brand has a cookieConsentId, otherwise false
 */
export const checkIsCookieConsentEnabledForBrand = (brand: Brand) =>
  Boolean(brand.cookieConsentId)

/**
 * Checks whether the passed-in brand is Vio
 * @param brand - The brand object to check
 * @returns True if brand.code is 'vio', otherwise false
 */
export const checkIsBrandVio = (brand: Brand) => brand.code === 'vio'

/**
 * Checks whether the passed-in brand is Etrip
 * @param brand - The brand object to check
 * @returns True if brand.code is 'etrip', otherwise false
 */
export const checkIsBrandEtrip = (brand: Brand) => brand.code === 'etrip'

/**
 * Checks whether the passed-in brand is GoSeek
 * @param brand - The brand object to check
 * @returns True if brand.code is 'goseek', otherwise false
 */
export const checkIsBrandGoSeek = (brand: Brand) => brand.code === 'goseek'

/**
 * Checks whether the passed-in brand is Kiwi
 * @param brand - The brand object to check
 * @returns True if brand.code is 'kiwi', otherwise false
 */
export const checkIsBrandKiwi = (brand: Brand) => brand.code === 'kiwi'

/**
 * Checks whether the passed-in brand is DealBase
 * @param brand - The brand object to check
 * @returns True if brand.code is 'dealbase', otherwise false
 */
export const checkIsBrandDealbase = (brand: Brand) => brand.code === 'dealbase'

/**
 * Checks whether the passed-in brand is CheckMyBus
 * @param brand - The brand object to check
 * @returns True if brand.code is 'checkmybus', otherwise false
 */
export const checkIsBrandCheckMyBus = (brand: Brand) =>
  brand.code === 'checkmybus'

/**
 * Checks whether the brand is internal (Vio, Etrip, GoSeek)
 * @param brand - The brand object to check
 * @returns True if brand.isInternal is set to true, otherwise false
 */
export const checkIsBrandInternal = (brand: Brand) => brand.isInternal

/**
 * Checks whether the passed-in brand is demoBasic.
 * @param brand - The brand object to check.
 * @returns True if the brand's code is 'demoBasic', otherwise false.
 */
export const checkIsBrandDemoBasic = (brand: Brand) =>
  brand.code === 'demoBasic'

/**
 * Checks whether the passed-in brand is demo.
 * @param brand - The brand object to check.
 * @returns True if the brand's code is 'demo', otherwise false.
 */
export const checkIsBrandDemo = (brand: Brand) => brand.code === 'demo'

/**
 * Checks whether the passed-in brand is a brand that locks offers by default
 * @param brand - The brand object to check
 * @returns True if brand.offersLockedByDefault is true, otherwise false
 */
export const checkIsBrandOffersLockedByDefault = (brand: Brand) =>
  brand.offersLockedByDefault

/**
 * Returns the brandCode for the matching partnerKey or "vio" if no matching partnerKey is found
 * @param partnerKey - The partnerKey to match against known brand partnerKeys
 * @returns The brand code matching the partnerKey, or 'vio' if none found
 */
export const getBrandCodeFromPartnerKey = (partnerKey?: string): BrandCode => {
  for (const brandCode in brands) {
    const typedBrandCode = brandCode as BrandCode

    if (brands[typedBrandCode].partnerKey === partnerKey) {
      return brands[typedBrandCode].code
    }
  }
  return brands.vio.code
}

/**
 * Returns the Vio brand object
 * @returns The brand configuration for 'vio'
 */
export const getVioBrand = () => brands.vio

/**
 * Returns the search app host based on brand and app environment
 *  ie: https://demo.vio.com for demo brand prod, https://tst.vio.com for vio brand staging
 * @param params - The parameters object
 * @param params.brand - The brand object containing site addresses
 * @param params.appEnv - The app environment string (e.g. "production", "staging")
 * @returns The appropriate search app host URL
 */
export const getSearchHost = ({
  brand,
  appEnv
}: {
  brand: Brand
  appEnv: string
}) => {
  const prodAddress =
    brand?.siteAddresses?.address || brands.vio?.siteAddresses?.address
  const stagingAddress =
    brand?.environmentAddresses?.stagingAddress ||
    brands.vio.environmentAddresses?.stagingAddress

  return getIsProductionAppEnv(appEnv) ? prodAddress : stagingAddress
}

/**
 * Returns the checkout app host based on brand and app environment
 * ie: https://secure.demo.vio.com for demo brand prod, https://checkout.stag.bofh.fih.io for vio brand staging
 * @param params - The parameters object
 * @param params.brand - The brand object containing checkout addresses
 * @param params.appEnv - The app environment string (e.g. "production", "staging")
 * @returns The appropriate checkout host URL
 */
export const getCheckoutHost = ({
  brand,
  appEnv
}: {
  brand: Brand
  appEnv: string
}) => {
  const prodAddress =
    brand?.siteAddresses?.checkoutAddress ||
    brands.vio.siteAddresses?.checkoutAddress
  const stagingAddress =
    brand?.environmentAddresses?.stagingCheckoutAddress ||
    brands.vio.environmentAddresses?.stagingCheckoutAddress

  return getIsProductionAppEnv(appEnv) ? prodAddress : stagingAddress
}

/**
 * Returns the bookings app host based on brand and app environment
 * ie: https://my.demo.vio.com for demo brand prod, https://my.tst.vio.com for vio brand staging
 * @param params - The parameters object
 * @param params.brand - The brand object containing booking management addresses
 * @param params.appEnv - The app environment string (e.g. "production", "staging")
 * @returns The appropriate bookings management host URL
 */
export const getMyBookingsHost = ({
  brand,
  appEnv
}: {
  brand: Brand
  appEnv: string
}) => {
  const prodAddress =
    brand?.siteAddresses?.bookingManagementAddress ||
    brands.vio.siteAddresses?.bookingManagementAddress
  const stagingAddress =
    brand?.environmentAddresses?.stagingBookingManagementAddress ||
    brands.vio.environmentAddresses?.stagingBookingManagementAddress

  return getIsProductionAppEnv(appEnv) ? prodAddress : stagingAddress
}

/**
 * Returns the help page URL in the appropriate localized language, if supported
 * @param brand - The brand object containing help links and locale info
 * @param lang - The language code (e.g. "en", "fr") to localize the URL
 * @returns The localized help URL, if available
 */
export const getLocalizedHelpUrl = (brand: Brand, lang = '') =>
  getLocalizedUrl(brand.links.helpUrl, lang, brand)

/**
 * Returns the contact page URL in the appropriate localized language, if supported
 * @param brand - The brand object containing contact links and locale info
 * @param lang - The language code (e.g. "en", "fr") to localize the URL
 * @returns The localized contact URL, if available
 */
export const getLocalizedContactUrl = (brand: Brand, lang = '') =>
  getLocalizedUrl(brand.links.contactUrl, lang, brand)

/**
 * Validates if the language is supported and returns the localized URL.
 * In case the language contains a region code, only the country code is used.
 * @param url - The base URL to be localized
 * @param lang - The language code (with optional region, e.g. "en-US")
 * @param brand - The brand object containing the supported locales
 * @returns The localized URL if it matches a supported locale, or the original URL
 */
const getLocalizedUrl = (url: string | undefined, lang = '', brand: Brand) => {
  const [countryCode] = lang.split('-')

  if (url && brand?.customerServiceLocales?.includes(countryCode))
    return localizeVioSupportLink(url, countryCode)

  return url
}

/**
 * Localize the given Vio support link if the domain is 'support.vio.com'
 * @param url - The URL to be potentially localized
 * @param lang - The language to localize (e.g. "en", "es")
 * @returns The localized or original URL
 */
const localizeVioSupportLink = (url = '', lang = '') => {
  const {pathname = '', hostname} = parseUrl(url)

  if (hostname !== 'support.vio.com') return url

  if (pathname == '/') return `${url}hc/${lang}`
  if (pathname.includes('/hc/en-us'))
    return url.replace('/hc/en-us', `/hc/${lang}`)

  return url
}
