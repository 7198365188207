/* eslint-disable jsdoc/check-param-names, jsdoc/require-param */
import {Dispatch, useEffect} from 'react'
import {useDispatch} from 'react-redux'
import {lensPath, set} from 'ramda'

import {useBrand} from '../../_web/brand/hooks/useBrand'
import {trackEvent} from '../../analytics/modules/actions'
import {
  Action,
  AnalyticsContext,
  Category,
  Entity
} from '../../analytics/types/Events'
import AnalyticsUtils from '../../analytics/utils'
import {loadFullStory} from '../../analytics/utils/FullStory'
import {postMessageToWebView, RNWebViewMessageTypes} from '../../native'
import {getConnection} from '../modules/selectors'
import {setIsLoading, setUser} from '../modules/slice'
import {getCurrentUserService} from '../services'
import {User} from '../types/Cognito'

interface FetchUserProps {
  publicUrl?: string | null
}

/**
 * Options for the `updateUser` function.
 * @param publicUrl - Public URL for FullStory integration
 * @param dispatch - Redux dispatch function
 * @param skipLoadingState - Whether to skip setting loading state
 */
type UpdateUserProps = {
  publicUrl?: FetchUserProps['publicUrl']
  dispatch: Dispatch<unknown>
  skipLoadingState?: boolean
}

/**
 * Updates the user state and handles authentication-related side effects.
 * @param props - The user update options
 * @returns The authenticated user object if successful
 */
export const updateUser = async ({
  publicUrl,
  dispatch,
  skipLoadingState = false
}: UpdateUserProps) => {
  if (!skipLoadingState) dispatch(setIsLoading(true))

  try {
    const {user, userPoolId} = await getCurrentUserService()
    const isUserAuthenticated = Boolean(user?.id)

    AnalyticsUtils.resetAnalytics(user?.attributes?.sub)

    postMessageToWebView({
      type: RNWebViewMessageTypes.USER_ID,
      payload: {userId: user?.attributes?.sub}
    })

    if (isUserAuthenticated) {
      const payload = {
        user,
        isAuthenticated: true,
        shouldSeeOffersUnlocked: true,
        userPoolId
      }

      const userWithAuthenticationMethod = set(
        lensPath(['user', 'attributes', 'authenticationMethod']),
        getConnection(user as User),
        payload
      )

      dispatch(setUser(userWithAuthenticationMethod))

      postMessageToWebView({
        type: RNWebViewMessageTypes.USER_AUTHENTICATED,
        payload: {user: user ?? undefined}
      })

      if (publicUrl) loadFullStory(publicUrl)

      dispatch(setIsLoading(false))
      return user
    }
  } catch (error) {
    dispatch(
      trackEvent({
        category: Category.System,
        entity: Entity.Search,
        action: Action.Errored,
        analyticsContext: {
          [AnalyticsContext.ErrorContext]: {
            errorType: 'CognitoAuthError',
            errorMessage: (error as Error).message
          }
        }
      })
    )
  } finally {
    dispatch(setIsLoading(false))
  }
}

/**
 * Component that handles user authentication state on mount
 * @param options - The component props
 * @returns Always returns null
 */
export const FetchUser = ({publicUrl}: FetchUserProps) => {
  const dispatch = useDispatch()
  const {brandShowSignIn} = useBrand()

  useEffect(() => {
    if (!brandShowSignIn) {
      dispatch(setIsLoading(false))
      return
    }

    updateUser({publicUrl, dispatch})
  }, [brandShowSignIn, dispatch])

  return null
}
