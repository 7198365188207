import React from 'react'
import {FormattedDate} from 'react-intl'
import {useSelector} from 'react-redux'
import {getSuggestionSearchValue} from 'modules/searchBox/selectors'
import {dateStringToMiddayDate} from 'utils'

import {Icon} from '@daedalus/atlas/Icon'
import {SuggestionItemDataType} from '@daedalus/core/src/autocomplete/types'
import {FormattedMessageWrapper} from '@daedalus/core/src/localization/components/FormattedMessage'
import {roomsToConfigurationObject} from '@daedalus/core/src/room/business/roomConfiguration'
import {guestPickerMessages} from '@daedalus/shared/src/search/GuestPicker/guestPickerMessages'

import {
  CloseIconWrapper,
  SuggestionContentWrapper,
  SuggestionElement,
  SuggestionIconWrapper,
  SuggestionTextElement,
  SuggestionTextElementDetail,
  SuggestionTextWrapper,
  SuggestionTypeLabel
} from './styles'

const TYPE_TO_ICON_MAP = {
  history: 'Clock',
  area: 'Pin',
  property: 'Building',
  airport: 'PlaneTakeOff',
  station: 'Bus',
  city: 'Buildings',
  country: 'Pin',
  address: 'Street',
  close: 'Close'
}

const getIcon = (type: string | null | undefined = 'place') => {
  return <Icon name={TYPE_TO_ICON_MAP[type]} size="md" />
}

const renderShortFormattedDate = (value: string): React.ReactNode => (
  <FormattedDate
    value={dateStringToMiddayDate(value)}
    month="short"
    day="2-digit"
  />
)

interface Props {
  suggestionDetails: SuggestionItemDataType

  isFocused?: boolean
  onUserHistoryCloseIconClick?: () => void
}
export const SuggestionItem: React.FC<Props> = ({
  suggestionDetails,
  isFocused,
  onUserHistoryCloseIconClick
}) => {
  const freeTextString = useSelector(getSuggestionSearchValue)

  const {
    objectName,
    objectParentName,
    objectPlaceTypeName,
    checkIn,
    checkOut,
    rooms,
    isUserSearchHistory,
    objectType,
    isFreeTextSearch
  } = suggestionDetails

  const placeTypeFormattedTextProps = {
    id: objectPlaceTypeName,
    defaultMessage: objectPlaceTypeName
  }

  const handleCloseClick = e => {
    onUserHistoryCloseIconClick?.()
    e.stopPropagation()
  }

  const {numberOfAdults, numberOfChildren, numberOfRooms} =
    roomsToConfigurationObject(rooms)

  const numberOfGuests = numberOfAdults + numberOfChildren

  // Render user search history
  if (isUserSearchHistory) {
    return (
      <SuggestionElement
        data-id="DestinationSuggestion--history"
        isFocused={isFocused}
      >
        <SuggestionContentWrapper>
          <SuggestionIconWrapper>{getIcon('history')}</SuggestionIconWrapper>
          <SuggestionTextWrapper>
            <SuggestionTextElement>{objectName}</SuggestionTextElement>
            <SuggestionTextElementDetail>
              {renderShortFormattedDate(checkIn)}
              {` - `}
              {renderShortFormattedDate(checkOut)}
              {` · `}
              <FormattedMessageWrapper
                {...guestPickerMessages.guest}
                values={{count: numberOfGuests}}
              />
              {', '}
              <FormattedMessageWrapper
                {...guestPickerMessages.room}
                values={{count: numberOfRooms}}
              />
            </SuggestionTextElementDetail>
          </SuggestionTextWrapper>
        </SuggestionContentWrapper>
        <CloseIconWrapper onClick={handleCloseClick}>
          {getIcon('close')}
        </CloseIconWrapper>
      </SuggestionElement>
    )
  }

  // Render free text search only on SRP
  if (isFreeTextSearch) {
    return (
      <SuggestionElement
        data-id="DestinationSuggestion--freeText"
        isFocused={isFocused}
      >
        <SuggestionIconWrapper>
          <Icon name="SearchAi" size="md" />
        </SuggestionIconWrapper>
        <SuggestionTextWrapper>
          <SuggestionTextElement>
            <span dangerouslySetInnerHTML={{__html: freeTextString}} />
          </SuggestionTextElement>
        </SuggestionTextWrapper>
      </SuggestionElement>
    )
  }

  // Render normal suggestion
  return (
    <SuggestionElement
      data-id="DestinationSuggestion--place"
      isFocused={isFocused}
    >
      <SuggestionIconWrapper>
        {getIcon(objectPlaceTypeName)}
      </SuggestionIconWrapper>
      <SuggestionTextWrapper>
        <SuggestionTextElement>
          <span dangerouslySetInnerHTML={{__html: objectName}} />
          {objectPlaceTypeName && (
            <SuggestionTypeLabel>
              <FormattedMessageWrapper {...placeTypeFormattedTextProps} />
            </SuggestionTypeLabel>
          )}
        </SuggestionTextElement>
        {objectType !== 'address' && (
          <SuggestionTextElementDetail>
            {objectParentName}
          </SuggestionTextElementDetail>
        )}
      </SuggestionTextWrapper>
    </SuggestionElement>
  )
}
