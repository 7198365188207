import * as React from 'react'
import {Helmet} from 'react-helmet'
import {defineMessages, useIntl} from 'react-intl'
import {useSelector} from 'react-redux'
import {getAnchorHotelName, getSearchType} from 'modules/sapiSearch/selectors'
import {SearchType} from 'modules/sapiSearch/utils'
import {getHotelName, getSearchDisplayName} from 'modules/search/selectors'

import {useBrand} from '@daedalus/core/src/_web/brand/hooks/useBrand'

const titles = defineMessages({
  placePage: {
    id: 'title.placePage',
    defaultMessage: 'Hotels in {place}'
  },
  placePageWithHotelNameFilter: {
    id: 'title.hotelNameFilter',
    defaultMessage: '{hotelName} in {place}'
  }
})

const InjectPageHeadTags = () => {
  const intl = useIntl()
  const hotelName = useSelector(getHotelName)
  const highlightedHotelName = useSelector(getAnchorHotelName)
  const searchDisplayName = useSelector(getSearchDisplayName)
  const {brand, brandPageTitleMessage, brandPageTitleTagLineMessage} =
    useBrand()
  const searchType = useSelector(getSearchType)

  const generateTitleForHotelPage = (): string | null | undefined => {
    if (!highlightedHotelName) {
      return null
    }

    return highlightedHotelName
  }

  const generateTitleForPlacePage = (): string | null | undefined => {
    if (!searchDisplayName) {
      return null
    }

    if (hotelName) {
      return intl.formatMessage(titles.placePageWithHotelNameFilter, {
        brandName: brand.name,
        hotelName,
        place: searchDisplayName
      })
    }

    return intl.formatMessage(titles.placePage, {
      brandName: brand.name,
      place: searchDisplayName
    })
  }

  const getTitleForPageType = () => {
    switch (searchType) {
      case SearchType.Hotel: {
        return generateTitleForHotelPage()
      }

      case SearchType.Place: {
        return generateTitleForPlacePage()
      }

      default: {
        return null
      }
    }
  }

  const generateGenericTitle = (): string =>
    intl.formatMessage(brandPageTitleMessage)

  const generateTitleTagline = (): string =>
    intl.formatMessage(brandPageTitleTagLineMessage)

  const getTitle = () => {
    const genericTitle = generateGenericTitle()
    const titleTagline = generateTitleTagline()
    const pageTitleForPageType = getTitleForPageType()

    if (pageTitleForPageType) {
      return `${brand.name} | ${pageTitleForPageType} | ${titleTagline}`
    }

    return `${brand.name} | ${genericTitle}`
  }

  return (
    <Helmet>
      <title>{getTitle()}</title>
    </Helmet>
  )
}

export default InjectPageHeadTags
