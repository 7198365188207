import {defineMessages} from 'react-intl'

import {AuthenticationMethod} from './types/Auth'

/**
 * Defines the Cognito login callback route
 */
export const COGNITO_LOGIN_CALLBACK_ROUTE = '/authentication/callback'

/**
 * Defines the Cognito logout callback route
 */
export const COGNITO_LOGOUT_CALLBACK_ROUTE = '/logout/callback'

/**
 * Defines the Cognito routes
 */
export const COGNITO_ROUTES = [
  COGNITO_LOGIN_CALLBACK_ROUTE,
  COGNITO_LOGOUT_CALLBACK_ROUTE
]

/**
 * Defines the authentication method messages
 * @returns The authentication method messages
 */
export const authenticationMethodMessages = defineMessages({
  [AuthenticationMethod.Google]: {
    id: 'google',
    defaultMessage: 'Google'
  },
  [AuthenticationMethod.Apple]: {
    id: 'apple',
    defaultMessage: 'Apple'
  },
  [AuthenticationMethod.Facebook]: {
    id: 'facebook',
    defaultMessage: 'Facebook'
  },
  // this skips translations for app and sms as we do not need but for type safety
  [AuthenticationMethod.App]: {
    id: 'facebook',
    defaultMessage: 'Facebook'
  }
})
