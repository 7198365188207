import {defineMessages} from 'react-intl'

/**
 * Split booking bottom sheet messages
 */
export const messages = defineMessages({
  header: {
    id: 'dealBreakdown',
    defaultMessage: 'Split booking breakdown'
  },
  switchRoomsAndSave: {
    id: 'switchRoomsAndSave',
    defaultMessage: 'Switch rooms and save'
  },
  splitBooking: {
    id: 'splitBookingLabel',
    defaultMessage: 'Split booking'
  },
  splitBookingInfo: {
    id: 'splitBookingInfo',
    defaultMessage:
      'A split booking involves splitting your stay across two separate bookings. You’ll need to switch rooms part way through your stay, but it’s a great way to save money and get more availability at busy properties.'
  },
  primaryOfferSubtitle: {
    id: 'splitBookingPrimaryOfferSubtitle',
    defaultMessage: 'Available by making 2 bookings'
  },
  secondaryOfferSubtitle: {
    id: 'splitBookingSecondaryOfferSubtitle',
    defaultMessage: 'Split your stay across two bookings'
  },
  secondaryOfferSubtitleWithSavings: {
    id: 'splitBookingSecondaryOfferSubtitleWithSavings',
    defaultMessage: 'Split your stay across two bookings to save {savingValue}'
  },
  bottomSheetTitle: {
    id: 'splitBookingBottomSheetTitle',
    defaultMessage: 'You’ll need to make the following 2 bookings'
  },
  dates: {
    id: 'datesText',
    defaultMessage: 'Dates'
  },
  avgHeading: {
    id: 'averageNightlyPrice',
    defaultMessage: 'Average nightly price'
  },
  totalPrice: {
    id: 'totalPrice',
    defaultMessage: 'Total price'
  },
  nights: {
    id: 'numberOfNights',
    defaultMessage: '{count, plural, one {1 night} other {# nights}}'
  },
  guests: {
    id: 'numberOfGuests',
    defaultMessage: '{count, plural, one {1 guest} other {# guests}}'
  },
  rooms: {
    id: 'numberOfRooms',
    defaultMessage: '{count, plural, one {1 room} other {# rooms}}'
  },
  perNight: {
    id: 'perNight',
    defaultMessage: '/ night'
  },
  includingTaxes: {
    id: 'includingTaxesShort',
    defaultMessage: 'incl tax & fees'
  }
})
