import {defineMessages} from 'react-intl'

import type {IconName} from '@daedalus/atlas/Icon'

const amenitiesMessages = defineMessages({
  bath: {
    id: 'keyAmenities.bath',
    defaultMessage: 'Bath'
  },
  privateBath: {
    id: 'keyAmenities.privateBath',
    defaultMessage: 'Private bathroom'
  },
  shower: {
    id: 'keyAmenities.shower',
    defaultMessage: 'Shower'
  },
  freeWiFi: {
    id: 'keyAmenities.freeWiFi',
    defaultMessage: 'Free Wi-Fi'
  },
  wiFi: {
    id: 'keyAmenities.wiFi',
    defaultMessage: 'Wi-fi'
  },
  airConditioning: {
    id: 'keyAmenities.airConditioning',
    defaultMessage: 'Air Conditioning'
  },
  balcony: {
    id: 'keyAmenities.balcony',
    defaultMessage: 'Balcony'
  },
  patio: {
    id: 'keyAmenities.patio',
    defaultMessage: 'Patio'
  },
  heating: {
    id: 'keyAmenities.heating',
    defaultMessage: 'Heating'
  },
  accessibility: {
    id: 'keyAmenities.accessibility',
    defaultMessage: 'Wheelchair Accessible'
  },
  tv: {
    id: 'keyAmenities.tv',
    defaultMessage: 'TV'
  },
  coffeMaking: {
    id: 'keyAmenities.coffeeMaking',
    defaultMessage: 'Coffee/Tea Making Facilities'
  },
  hairdryer: {
    id: 'keyAmenities.hairdryer',
    defaultMessage: 'Hairdryer'
  },
  safetyBox: {
    id: 'keyAmenities.safetyBox',
    defaultMessage: 'Safety Deposit Box'
  },
  safe: {
    id: 'keyAmenities.safe',
    defaultMessage: 'Safe'
  },
  desk: {
    id: 'keyAmenities.desk',
    defaultMessage: 'Desk'
  },
  toilet: {
    id: 'keyAmenities.toilet',
    defaultMessage: 'Toilet'
  },
  wardrobe: {
    id: 'keyAmenities.wardrobe',
    defaultMessage: 'Wardrobe'
  },
  toiletries: {
    id: 'keyAmenities.toiletries',
    defaultMessage: 'Toiletries'
  },
  ironBoard: {
    id: 'keyAmenities.ironBoard',
    defaultMessage: 'Iron and ironing Board'
  },
  iron: {
    id: 'keyAmenities.iron',
    defaultMessage: 'Iron'
  }
})
export const RANKED_AMENITIES: {
  id: string
  defaultMessage: string
  icon: IconName
}[] = [
  {...amenitiesMessages.bath, icon: 'Bath'},
  {...amenitiesMessages.privateBath, icon: 'Bath'},
  {...amenitiesMessages.freeWiFi, icon: 'Wifi'},
  {...amenitiesMessages.shower, icon: 'Shower'},
  {...amenitiesMessages.wiFi, icon: 'Wifi'},
  {...amenitiesMessages.airConditioning, icon: 'Ice'},
  {...amenitiesMessages.balcony, icon: 'Patio'},
  {...amenitiesMessages.patio, icon: 'Patio'},
  {...amenitiesMessages.heating, icon: 'Temperature'},
  {...amenitiesMessages.accessibility, icon: 'Disability'},
  {...amenitiesMessages.tv, icon: 'Monitor'},
  {...amenitiesMessages.coffeMaking, icon: 'Mug2'},
  {...amenitiesMessages.hairdryer, icon: 'Hairdryer'},
  {...amenitiesMessages.safetyBox, icon: 'Safe'},
  {...amenitiesMessages.safe, icon: 'Safe'},
  {...amenitiesMessages.desk, icon: 'Trampoline'},
  {...amenitiesMessages.toilet, icon: 'ToiletRoll'},
  {...amenitiesMessages.wardrobe, icon: 'CoatHanger'},
  {...amenitiesMessages.toiletries, icon: 'SoapPump'},
  {...amenitiesMessages.ironBoard, icon: 'Iron'},
  {...amenitiesMessages.iron, icon: 'Iron'}
]
