import React, {ReactElement} from 'react'
import {FormattedDate} from 'react-intl'

import {Text} from '@daedalus/atlas/Text'
import {FormattedMessageWrapper} from '@daedalus/core/src/localization/components/FormattedMessage'
import {MiddayDate} from '@daedalus/core/src/utils/date'
import {guestPickerMessages} from '@daedalus/shared/src/search/GuestPicker/guestPickerMessages'

export interface DateGuestInfoType {
  /** Check in date */
  checkIn: MiddayDate
  /** Check out date */
  checkOut: MiddayDate
  /** Number of guests */
  numberOfGuests: number
  /** Number of rooms */
  numberOfRooms: number
}

const RoomGuestsText = ({
  numberOfGuests,
  numberOfRooms
}: {
  numberOfRooms: number
  numberOfGuests: number
}): ReactElement => {
  return (
    <>
      <FormattedMessageWrapper
        {...guestPickerMessages.guest}
        values={{count: numberOfGuests}}
      />
      {', '}
      <FormattedMessageWrapper
        {...guestPickerMessages.room}
        values={{count: numberOfRooms}}
      />
    </>
  )
}

export const RoomDateGuestInfo = ({
  checkIn,
  checkOut,
  numberOfGuests,
  numberOfRooms
}: DateGuestInfoType) => {
  return (
    <div>
      <Text variant="titleL">
        <FormattedMessageWrapper
          id="bookThisRoom"
          defaultMessage="Book this room"
        />
      </Text>
      <Text variant="bodyS" colorPath="content.neutral.c600">
        <FormattedDate value={checkIn} month="short" day="2-digit" />
        {' - '}
        <FormattedDate value={checkOut} month="short" day="2-digit" />
        {' · '}
        <RoomGuestsText
          numberOfGuests={numberOfGuests}
          numberOfRooms={numberOfRooms}
        />
      </Text>
    </div>
  )
}
