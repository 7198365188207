import {defineMessages} from 'react-intl'

export const datePickerMessages = defineMessages({
  nights: {
    id: 'numberOfNights',
    defaultMessage: '{count, plural, one {1 night} other {# nights}}'
  },
  pleaseSelectDates: {
    id: 'selectDates',
    defaultMessage: 'Select'
  },
  checkInDate: {
    id: 'checkIn',
    defaultMessage: 'Check-in'
  },
  checkOutDate: {
    id: 'checkOut',
    defaultMessage: 'Check-out'
  },
  cheapestNightlyPrice: {
    id: 'cheapestNightlyPrice',
    defaultMessage: 'Cheapest nightly price'
  }
})
