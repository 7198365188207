import {defineMessages} from 'react-intl'
import {
  SORT_FIELD_DISTANCE,
  SORT_FIELD_GUEST_RATING,
  SORT_FIELD_PRICE,
  SORT_FIELD_STAR_RATING
} from 'modules/search/constants'
import {SortField, SortOrder} from 'modules/search/types'

import {
  SORT_FIELD_POPULARITY,
  SORT_ORDER_ASCENDING,
  SORT_ORDER_DESCENDING
} from '@daedalus/core/src/searchParams/constants'

export interface SortByTypeItem {
  key: string
  sortField: SortField
  sortOrder: SortOrder
}
export type SortByType = Array<SortByTypeItem>

export const SORT_BY: SortByType = [
  {
    key: 'bestMatch',
    sortField: SORT_FIELD_POPULARITY,
    sortOrder: SORT_ORDER_ASCENDING
  },
  {
    key: 'minPrice',
    sortField: SORT_FIELD_PRICE,
    sortOrder: SORT_ORDER_ASCENDING
  },
  {
    key: 'reviews',
    sortField: SORT_FIELD_GUEST_RATING,
    sortOrder: SORT_ORDER_DESCENDING
  },
  {
    key: 'starRatingLowToHigh',
    sortField: SORT_FIELD_STAR_RATING,
    sortOrder: SORT_ORDER_ASCENDING
  },
  {
    key: 'starRatingHighToLow',
    sortField: SORT_FIELD_STAR_RATING,
    sortOrder: SORT_ORDER_DESCENDING
  }
]

export const SORT_BY_WITH_DISTANCE: SortByType = [
  ...SORT_BY.slice(0, 3),
  {
    key: 'distance',
    sortField: SORT_FIELD_DISTANCE,
    sortOrder: SORT_ORDER_ASCENDING
  },
  ...SORT_BY.slice(3)
]

export const COMMON_SORT_LABELS = defineMessages({
  minPrice: {
    id: 'filters.sortDescending',
    defaultMessage: 'Lowest price'
  },
  bestMatch: {
    id: 'bestMatch',
    defaultMessage: 'Best match'
  },
  distance: {
    id: 'distance',
    defaultMessage: 'Distance'
  },
  reviews: {
    id: 'topReviewed',
    defaultMessage: 'Top reviewed'
  },
  starRatingLowToHigh: {
    id: 'hotelStarClassLowToHigh',
    defaultMessage: 'Hotel star class - low to high'
  },
  starRatingHighToLow: {
    id: 'hotelStarClassHighToLow',
    defaultMessage: 'Hotel star class - high to low'
  }
})

export const TIERS = {
  Gems: 'Gems',
  PrivateDeal: 'Exclusive'
}
