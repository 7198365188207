import {Dispatch} from 'redux'

import {trackEvent} from '@daedalus/core/src/analytics/modules/actions'
import {
  Action,
  Category,
  Entity,
  Team
} from '@daedalus/core/src/analytics/types/Events'
import {SourceComponentType} from '@daedalus/core/src/auth/types/Auth'

// Email step
export const trackOnClickOneTimePassword = (
  sourceComponent: SourceComponentType | undefined,
  dispatch: Dispatch
) => {
  dispatch(
    trackEvent({
      category: Category.User,
      entity: Entity.EmailAuthentication,
      action: Action.Clicked,
      component: sourceComponent || undefined,
      team: Team.Retention
    })
  )
}

export const trackErrorOnRequestOneTimePassword = (
  sourceComponent: SourceComponentType | undefined,
  error: string,
  dispatch: Dispatch
) => {
  dispatch(
    trackEvent({
      category: Category.System,
      entity: Entity.Authentication,
      action: Action.Errored,
      component: sourceComponent || undefined,
      payload: {error, authenticationMethod: 'Email'},
      team: Team.Retention
    })
  )
}

export const trackValidationError = (
  sourceComponent: SourceComponentType | undefined,
  errorType: string,
  errorMessage: string,
  dispatch: Dispatch
) => {
  dispatch(
    trackEvent({
      category: Category.System,
      entity: Entity.Authentication,
      action: Action.Errored,
      component: sourceComponent || undefined,
      team: Team.Retention,
      payload: {
        errorContext: {
          errorType,
          errorMessage
        }
      }
    })
  )
}
// Otp step
export const trackOnClickVerifyOneTimePassword = (
  sourceComponent: SourceComponentType | undefined,
  dispatch: Dispatch
) => {
  dispatch(
    trackEvent({
      category: Category.User,
      entity: Entity.OTPAuthentication,
      action: Action.Submitted,
      component: sourceComponent,
      team: Team.Retention
    })
  )
}

export const trackSuccessfulAuthentication = (
  sourceComponent: SourceComponentType | undefined,
  dispatch: Dispatch
) => {
  dispatch(
    trackEvent({
      category: Category.User,
      entity: Entity.Authentication,
      action: Action.Succeeded,
      component: sourceComponent || undefined,
      team: Team.Retention,
      payload: {
        authenticationMethod: 'Email'
      }
    })
  )
}

export const trackErrorOnVerifyOneTimePassword = (
  sourceComponent: SourceComponentType | undefined,
  errorType: string,
  errorMessage: string,
  dispatch: Dispatch
) => {
  dispatch(
    trackEvent({
      category: Category.User,
      entity: Entity.Authentication,
      action: Action.Errored,
      component: sourceComponent || undefined,
      team: Team.Retention,
      payload: {
        errorType,
        errorMessage
      }
    })
  )
}
