import React from 'react'

import {ContentWrapper} from '@daedalus/atlas/helpers/ContentWrapper'
import {Icon} from '@daedalus/atlas/Icon'
import {Tag} from '@daedalus/atlas/Tag'
import {Text} from '@daedalus/atlas/Text'
import FormattedMessageWrapper from '@daedalus/core/src/localization/components/FormattedMessage'

export const NonRefundable = ({isPostBooking}: {isPostBooking?: boolean}) => {
  return (
    <>
      <ContentWrapper paddingBottom="s500" paddingTop="s300">
        <Text variant="bodyS">
          <FormattedMessageWrapper
            id="cancellation.nonRefundableDescription"
            defaultMessage="You won't be able to get a refund if you cancel or don’t show up."
          />
        </Text>
      </ContentWrapper>
      {!isPostBooking && (
        <>
          <Tag
            variant="info"
            size="sm"
            iconStart={<Icon name="Information" size="sm" />}
          >
            <Text variant="labelXS">
              <FormattedMessageWrapper
                id="cancellationPenalties.travelTip"
                defaultMessage="Travel tip"
              />
            </Text>
          </Tag>
          <ContentWrapper paddingBottom="s500" paddingTop="s200">
            <Text variant="bodyS">
              <FormattedMessageWrapper
                id="cancellation.travelTipDescription"
                defaultMessage="Booking non-refundable rates can be a good way to {saveExtra} if you are certain your plans won’t change."
                values={{
                  saveExtra: (
                    <Text
                      colorPath="content.success.c600"
                      variant="bodyS"
                      tag="span"
                    >
                      <FormattedMessageWrapper
                        id="cancellationPenalties.saveExtra"
                        defaultMessage="save extra"
                      />
                    </Text>
                  )
                }}
              />
            </Text>
          </ContentWrapper>
        </>
      )}
    </>
  )
}
