import React from 'react'

import {FormattedMessageWrapper} from '@daedalus/core/src/localization/components/FormattedMessage'

interface Props {
  numberOfGuests: number
  numberOfRooms?: number
  separator?: string
}

export const GuestPickerButtonMessage = ({
  numberOfGuests,
  numberOfRooms,
  separator = ' · '
}: Props) => (
  <>
    <FormattedMessageWrapper
      id="numberOfGuests"
      defaultMessage="{count, plural, one {1 guest} other {# guests}}"
      values={{
        count: numberOfGuests
      }}
    />
    {numberOfRooms && (
      <>
        <span>{separator}</span>
        <FormattedMessageWrapper
          id="numberOfRooms"
          defaultMessage="{count, plural, one {1 room} other {# rooms}}"
          values={{
            count: numberOfRooms
          }}
        />
      </>
    )}
  </>
)
