import {defineMessages} from 'react-intl'

import {OfferAlertVariant} from './constants'

export const offerAlertMessages = defineMessages({
  priceIncreaseMessage: {
    id: 'priceIncreaseMessage',
    defaultMessage: 'The nightly price just increased by {price}. '
  },
  priceDropMessage: {
    id: 'priceDropMessage',
    defaultMessage: 'The nightly price just dropped by {price}. '
  },
  why: {
    id: 'why',
    defaultMessage: 'Why?'
  },
  priceChange: {
    id: 'priceChange',
    defaultMessage: 'Price change'
  },
  priceChangeOverlayContent: {
    id: 'priceChangeOverlayContent',
    defaultMessage:
      'Prices change based on demand. The latest prices can take a few minutes to reach us and can change after making a selection.'
  },
  cancellationPolicyMessage: {
    id: 'cancellationPolicyChangeMessage',
    defaultMessage: 'Cancellation policy has changed to {penalty}. '
  },
  mealMessage: {
    id: 'mealChangeMessage',
    defaultMessage: 'Meal option has been removed. '
  },
  dealUpdated: {
    id: 'dealUpdated',
    defaultMessage: 'Deal updated'
  },
  dealUpdatedOverlayContent: {
    id: 'dealUpdatedOverlayContent',
    defaultMessage:
      'Your selected deal is no longer available, so we’ve selected the best value alternative. Check the details before you complete your booking.'
  },
  dealUpdatedButton: {
    id: 'understood',
    defaultMessage: 'Understood'
  }
})

export const overlayTranslations = {
  price: {
    centerContent: {
      id: offerAlertMessages.priceChange.id,
      defaultMessage: offerAlertMessages.priceChange.defaultMessage
    },
    content: {
      id: offerAlertMessages.priceChangeOverlayContent.id,
      defaultMessage:
        offerAlertMessages.priceChangeOverlayContent.defaultMessage
    }
  },
  perks: {
    centerContent: {
      id: offerAlertMessages.dealUpdated.id,
      defaultMessage: offerAlertMessages.dealUpdated.defaultMessage
    },
    content: {
      id: offerAlertMessages.dealUpdatedOverlayContent.id,
      defaultMessage:
        offerAlertMessages.dealUpdatedOverlayContent.defaultMessage
    }
  }
}

export const variantTranslations = {
  [OfferAlertVariant.PriceDrop]: {
    id: offerAlertMessages.priceDropMessage.id,
    defaultMessage: offerAlertMessages.priceDropMessage.defaultMessage
  },
  [OfferAlertVariant.PriceIncrease]: {
    id: offerAlertMessages.priceIncreaseMessage.id,
    defaultMessage: offerAlertMessages.priceIncreaseMessage.defaultMessage
  },
  [OfferAlertVariant.CancellationPenalty]: {
    id: offerAlertMessages.cancellationPolicyMessage.id,
    defaultMessage: offerAlertMessages.cancellationPolicyMessage.defaultMessage
  },
  [OfferAlertVariant.Meal]: {
    id: offerAlertMessages.mealMessage.id,
    defaultMessage: offerAlertMessages.mealMessage.defaultMessage
  }
}
