import {defineMessages} from 'react-intl'

export const filterMessages = defineMessages({
  // All filters
  filters: {
    id: 'filters',
    defaultMessage: 'Filters'
  },
  allFilters: {
    id: 'filters.all',
    defaultMessage: 'All filters'
  },
  // Free cancellation
  freeCancellationTitle: {
    id: 'cancellationPenalties.freeCancellation',
    defaultMessage: 'Free cancellation'
  },
  freeCancellationSubtitle: {
    id: 'filters.freeCancellationSubtitle',
    defaultMessage: 'Show deals with free cancellation'
  },
  // Popular
  popular: {
    id: 'popularFilters',
    defaultMessage: 'Popular filters'
  },
  // Star rating
  hotelStarClass: {
    id: 'filters.hotelStarClass',
    defaultMessage: 'Hotel star class'
  },
  // Price
  nightlyPrice: {
    id: 'nightlyPrice',
    defaultMessage: 'Nightly price'
  },
  priceFilter: {
    id: 'priceFilter',
    defaultMessage: 'Price'
  },
  priceSubtitle: {
    id: 'includingTaxesLong',
    defaultMessage: 'including taxes and fees'
  },
  // Review
  reviewScore: {
    id: 'filters.reviewScore',
    defaultMessage: 'Review score'
  },
  // Facilities
  facilities: {
    id: 'facilities',
    defaultMessage: 'Facilities'
  },
  facilitiesExpanded: {
    id: 'showLess',
    defaultMessage: 'Show less'
  },
  facilitiesContracted: {
    id: 'filters.facilitiesContracted',
    defaultMessage: 'See all facilities'
  },
  // Themes
  themes: {
    id: 'themes',
    defaultMessage: 'Themes'
  },
  themesContracted: {
    id: 'desktopFilters.allThemes',
    defaultMessage: 'See all themes'
  },
  // Property type
  propertyType: {
    id: 'propertyType',
    defaultMessage: 'Property type'
  },
  propertyTypeContracted: {
    id: 'filters.propertyTypeContracted',
    defaultMessage: 'See all property types'
  },
  // Common
  noFiltersAvailable: {
    id: 'noFiltersAvailable',
    defaultMessage: 'No filters available for your search'
  },
  allTypes: {
    id: 'allPropertyTypes',
    defaultMessage: 'All'
  },
  popularTypes: {
    id: 'popularPropertyTypes',
    defaultMessage: 'popular'
  },
  hotelsTypes: {
    id: 'propertyType.hotelsTitle',
    defaultMessage: 'Hotels'
  },
  vacationRentalsTypes: {
    id: 'propertyType.vacationRentalsTitle',
    defaultMessage: 'Vacation rentals'
  },
  applyFilters: {
    id: 'filters.applyPlural',
    defaultMessage: 'Apply {count, plural, one {1 filter} other {# filters}}'
  },
  // Show less
  showLess: {
    id: 'showLess',
    defaultMessage: 'Show less'
  },
  // Show more
  showMore: {
    id: 'showMore',
    defaultMessage: 'Show more'
  },
  // Meal Plans
  mealPlans: {
    id: 'filters.mealPlans',
    defaultMessage: 'Meal plans'
  },
  breakfast: {
    id: 'mealType.breakfastIncluded',
    defaultMessage: 'Breakfast included'
  },
  allInclusive: {
    id: 'mealType.allInclusive',
    defaultMessage: 'All inclusive'
  }
})
