'use client'

import React, {ReactNode, useEffect} from 'react'
import {Provider as ReduxProvider, useSelector} from 'react-redux'
import {Router} from 'react-router-dom'
import {DebugPanelModules, DebugPanelProvider} from '@vio/debug-panel'
import {getIsoptimizelyDisabled} from '@vio/debug-panel/src/modules/localstorage'
import {getPersistedSimulationFlag} from '@vio/debug-panel/src/utils/scenarioSimulator'
import {TmsPanelProvider} from '@vio/tms'
import {CaptureVioClickID} from 'components/CaptureVioClickID'
import SyncStateWithDeviceLayout from 'components/data/SyncStateWithDeviceLayout'
import {useUrlUpdater} from 'components/data/WithUrlUpdater'
import {ErrorBoundaryWrapper as ErrorBoundary} from 'components/ErrorBoundary'
import InitializeABTesting from 'components/InitializeABTesting'
import InitializeMeta from 'components/InitializeMeta'
import InitializeSentry from 'components/InitializeSentry'
import {NewLanguagesABTestOverrides} from 'components/NewLanguagesABTestOverrides/NewLanguagesABTestOverrides'
import {PreferencesOverlay} from 'components/PreferencesOverlay'
import {PriceWatchOverlays} from 'components/PriceWatch/components/PriceWatchOverlays'
import {PriceWatchTrackingsSync} from 'components/PriceWatch/components/PriceWatchTrackingsSync'
import InjectPageHeadTags from 'components/Seo/InjectPageHeadTags'
import InjectPageMetaDescription from 'components/Seo/InjectPageMetaDescription'
import {TrackGoogleTranslateUsage} from 'components/TrackGoogleTranslateUsage/TrackGoogleTranslateUsage'
import {TrackSSRExperiments} from 'components/TrackSSRExperiments/TrackSSRExperiments'
import {Web2AppPopup} from 'components/Web2AppPopup/Web2AppPopup'
import {usePageLoadStartTimer} from 'hooks/usePageLoad'
import {scenariosList} from 'mocks/scenarios'
import {setScreenshotTaken} from 'modules/analytics/slice'
import {
  getAnonymousId,
  getCurrencyCode,
  getDeviceLayout,
  getIsSyntheticTest,
  getLanguageCode,
  getUserCountryCode
} from 'modules/meta/selectors'
import Settings from 'Settings'
import {store} from 'store'
import {history} from 'utils'

import {DeviceLayoutProvider} from '@daedalus/atlas/context/deviceLayout'
import {ToastProvider} from '@daedalus/atlas/context/toastController'
import GridGlobalStyles from '@daedalus/atlas/foundation/GridGlobalStyles'
import {CssBaseline} from '@daedalus/atlas/helpers/CssBaseline'
import FontsLoader from '@daedalus/atlas/helpers/FontsLoader'
import {useBrand} from '@daedalus/core/src/_web/brand/hooks/useBrand'
import {RouterProvider} from '@daedalus/core/src/_web/router/context/RouterProvider'
import {getQueryStringObject} from '@daedalus/core/src/_web/utils/url'
import {FetchAclDecision} from '@daedalus/core/src/acl/components/FetchAclDecision'
import InitializeDataDogClient from '@daedalus/core/src/analytics/components/DataDogClient'
import FullStoryLoader, {
  InjectFullStoryBodyClass
} from '@daedalus/core/src/analytics/components/FullStoryLoader'
import {InitializeCustomerIo} from '@daedalus/core/src/analytics/components/InitializeCustomerIo'
import TrackScreenshot from '@daedalus/core/src/analytics/components/TrackScreenshot'
import {TrackWebVitals} from '@daedalus/core/src/analytics/components/TrackWebVitals'
import {getPersistedVclidValue} from '@daedalus/core/src/analytics/utils/vclidCookie'
import {FetchUser} from '@daedalus/core/src/auth/components/FetchUser'
import SyncStateWithPrivilege from '@daedalus/core/src/auth/components/SyncStateWithPrivilege'
import TrackUserAuthentication from '@daedalus/core/src/auth/components/TrackUserAuthentication'
import {configureAmplify} from '@daedalus/core/src/auth/services'
import {
  checkIsBrandVio,
  getCheckoutHost,
  getMyBookingsHost
} from '@daedalus/core/src/brand/utils'
import OpticksProvider, {
  experimentVariationsStore
} from '@daedalus/core/src/experiments/components/OpticksProvider'
import {getDataFile} from '@daedalus/core/src/experiments/modules/selectors'
import ConfigureI18n from '@daedalus/core/src/localization/components/ConfigureI18n'
import LanguageConfig from '@daedalus/core/src/localization/components/LanguageConfig'
import {GetBoVioConfiguration} from '@daedalus/core/src/offer/services/offerConfigurationApi/GetBoVioConfiguration'
import {urlHasParam} from '@daedalus/core/src/utils/url'
import {EmailSignUpProvider} from '@daedalus/shared/src/authentication/Email/EmailSignUpProvider'
import {EmailFlow} from '@daedalus/shared/src/authentication/Email/index'
import {OneTimePasswordFlow} from '@daedalus/shared/src/authentication/OneTimePassword/index'
import {SusiProvider} from '@daedalus/shared/src/authentication/SUSI/bottomSheet/SusiBottomSheet/SusiProvider'
import {PasswordlessLogin} from '@daedalus/shared/src/authentication/SUSI/overlay'
import {DealFreeze} from '@daedalus/shared/src/dealFreeze'
import {ApplicationNames} from '@daedalus/shared/src/dealFreeze/modules/slice'
import {SyncStateWithPriceWatchHoldoutGroup} from '@daedalus/shared/src/priceWatch/components/SyncStateWithPriceWatchHoldoutGroup'
import PerformanceMonitor from '@daedalus/shared/src/utils/PerformanceMonitor/PerformanceMonitor'
import {ProfileKey} from '@findhotel/sapi'

import {getSearchIdFromConditionalSource} from './modules/searchApi/selectors'
import {openSearchBox, setIsSearchBoxActive} from './modules/searchBox/slice'
import {imageProvider} from './utils/imageProvider'

configureAmplify()

interface Props {
  children: ReactNode
}

/**
 * This component wraps all pages with the necessary context providers
 */
const AppProviders = ({children}: Props) => {
  const languageCode = useSelector(getLanguageCode)
  const {brand} = useBrand()
  const anonymousId = useSelector(getAnonymousId)
  const userCountryCode = useSelector(getUserCountryCode)
  const currencyCode = useSelector(getCurrencyCode)
  const isMobile = useSelector(getDeviceLayout) !== 'desktop'
  const isSyntheticTest = useSelector(getIsSyntheticTest)
  const {updateLocation} = useUrlUpdater()

  const appEnv = Settings.get('APP_ENV')
  const publicUrl = Settings.get('CLIENT_ASSETS_ENDPOINT')
  const sapiProfileKey = Settings.get('CLIENT_SAPI_PROFILE_KEY') as ProfileKey

  const boVioEndpoint = Settings.get('CLIENT_BOVIO_ENDPOINT')
  const appVersion = Settings.get('APP_VERSION')
  const customerIoSiteId = Settings.get('WEBSITE_CUSTOMER_IO_TRACKING_SITE_ID')
  const checkoutUrl = getCheckoutHost({brand, appEnv})
  const bookingManagementUrl = getMyBookingsHost({
    brand,
    appEnv
  })

  const vclid = getQueryStringObject()?.vclid || getPersistedVclidValue()

  const forceDisableOptimizelyLog = getIsoptimizelyDisabled()

  const oneTimePasswordUrl = `${Settings.get(
    'MEMBERSHIP_API_ENDPOINT'
  )}auth/send-one-time-password${checkIsBrandVio(brand) ? '' : '-etrip'}`

  const verifyUrl = `${Settings.get(
    'MEMBERSHIP_API_ENDPOINT'
  )}auth/verify-one-time-password${checkIsBrandVio(brand) ? '' : '-etrip'}`

  usePageLoadStartTimer()

  const datafile = useSelector(getDataFile)
  const optimizelyFlags = datafile?.featureFlags?.map(({key}) => key) || []

  useEffect(() => {
    if (
      urlHasParam({search: window.location.search, param: 'simulate'}) ||
      getPersistedSimulationFlag()
    ) {
      import('mocks/initializeSimulator').then(module => module.default())
    }
  }, [])

  return (
    <InitializeSentry>
      <InitializeMeta>
        {/* Capture and store Vio Click Identifier */}
        <CaptureVioClickID />
        <TrackScreenshot action={setScreenshotTaken} />
        <TrackGoogleTranslateUsage />
        <InitializeDataDogClient
          clientToken={Settings.get('CLIENT_DD_CLIENT_TOKEN')}
          appVersion={appVersion}
          appEnv={appEnv}
          applicationId={Settings.get('CLIENT_DD_RUM_APP_ID')}
          sampleRate={Number(Settings.get('CLIENT_DD_SAMPLE_RATE'))}
          premiumSampleRate={Number(
            Settings.get('CLIENT_DD_REPLAY_SAMPLE_RATE')
          )}
          service="search"
        />
        <OpticksProvider
          dataFileUrl={Settings.get('NEXT_PUBLIC_OPTIMIZELY_DATAFILE_URL')}
          disableOptimizelyLogs={forceDisableOptimizelyLog}
        >
          <InitializeABTesting>
            {/* Added as part of 2a98dd4d-add-new-languages--v2 */}
            <NewLanguagesABTestOverrides languageCode={languageCode}>
              <ConfigureI18n
                languageCode={languageCode}
                publicUrl={publicUrl}
                countryCode={userCountryCode}
              >
                <ErrorBoundary>
                  <ToastProvider>
                    <DeviceLayoutProvider>
                      <PerformanceMonitor />
                      <TrackSSRExperiments />
                      <CssBaseline />
                      <GridGlobalStyles />
                      <TmsPanelProvider appEnv={appEnv} />

                      <FullStoryLoader publicUrl={publicUrl} />
                      <InjectFullStoryBodyClass />
                      <InitializeCustomerIo
                        customerIoSiteId={customerIoSiteId}
                      />
                      <SyncStateWithDeviceLayout />
                      <LanguageConfig locale={languageCode} />

                      <Router history={history}>
                        <RouterProvider>
                          <TrackWebVitals />
                          <SyncStateWithPrivilege />
                          <SyncStateWithPriceWatchHoldoutGroup />

                          <FetchUser publicUrl={publicUrl} />
                          <PriceWatchTrackingsSync />
                          <FetchAclDecision anonymousId={anonymousId} />
                          <TrackUserAuthentication />
                          <FontsLoader
                            brand={brand}
                            languageCode={languageCode}
                          />
                          <InjectPageHeadTags />
                          <InjectPageMetaDescription />
                          <SusiProvider
                            brand={brand}
                            userCountryCode={userCountryCode}
                            anonymousId={anonymousId}
                          >
                            <DebugPanelProvider
                              isMobile={isMobile}
                              appEnv={appEnv}
                              isSyntheticTest={isSyntheticTest}
                              thirdPartyExperiments={optimizelyFlags}
                              meta={{anonymousId}}
                              variationsStoreReader={experimentVariationsStore}
                              scenarios={scenariosList}
                              modulesConfig={[
                                DebugPanelModules.EXPERIMENTS,
                                DebugPanelModules.SIMULATOR,
                                DebugPanelModules.SETTINGS,
                                DebugPanelModules.SORT
                              ]}
                              updateLocation={updateLocation}
                            >
                              {children}
                            </DebugPanelProvider>
                            <PriceWatchOverlays />
                            <PreferencesOverlay />
                          </SusiProvider>
                          <PasswordlessLogin
                            brand={brand}
                            languageCode={languageCode}
                            anonymousId={anonymousId}
                          />
                          <GetBoVioConfiguration
                            boVioUrl={boVioEndpoint}
                            brandCode={brand.code}
                            appEnv={appEnv}
                            urlParams={getQueryStringObject()}
                          />
                          <DealFreeze
                            imageProvider={imageProvider}
                            appCurrency={currencyCode}
                            sapiProfileKey={sapiProfileKey}
                            application={ApplicationNames.Search}
                            appVersion={appVersion}
                            appEnv={appEnv}
                            boVioEndpoint={boVioEndpoint}
                            optimizelyFlags={optimizelyFlags}
                            checkoutUrl={checkoutUrl}
                            bookingManagementUrl={bookingManagementUrl}
                            languageCode={languageCode}
                            actions={{
                              openSearchBox,
                              setIsSearchBoxActive
                            }}
                            getSearchId={getSearchIdFromConditionalSource}
                            getCountryCode={getUserCountryCode}
                            vclid={vclid ? String(vclid) : undefined}
                          />
                          <OneTimePasswordFlow
                            brand={brand}
                            languageCode={languageCode}
                            oneTimePasswordUrl={oneTimePasswordUrl}
                            verifyUrl={verifyUrl}
                            userCountryCode={userCountryCode}
                          />
                          <Web2AppPopup />
                          <EmailSignUpProvider
                            apiUrl={Settings.get('MEMBERSHIP_API_ENDPOINT')}
                          >
                            <EmailFlow />
                          </EmailSignUpProvider>
                        </RouterProvider>
                      </Router>
                    </DeviceLayoutProvider>
                  </ToastProvider>
                </ErrorBoundary>
              </ConfigureI18n>
            </NewLanguagesABTestOverrides>
          </InitializeABTesting>
        </OpticksProvider>
      </InitializeMeta>
    </InitializeSentry>
  )
}

const AppProvidersWithRedux = ({children}: Props) => (
  <ReduxProvider store={store}>
    <AppProviders>{children}</AppProviders>
  </ReduxProvider>
)

export default AppProvidersWithRedux
