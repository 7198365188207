import React, {lazy, ReactNode, Suspense, useEffect} from 'react'

import {AnimationType} from '@daedalus/atlas/Overlay'
import {OverlayPage} from '@daedalus/atlas/OverlayPage'
import {DatePickerType} from '@daedalus/core/src/datePicker/types'
import {FormattedMessageWrapper} from '@daedalus/core/src/localization/components/FormattedMessage'

import {DayInnerContainer} from '../DatePicker/ColorPriceDatePicker'

export interface Props {
  /** Date of Check In */
  checkIn: string | null | undefined
  /** Date of Check Out */
  checkOut: string | null | undefined
  /** Whether the overlay is open */
  isOpen: boolean
  /** If any, the type of picker that is open */
  openedDatePickerType: DatePickerType | null | undefined
  /** Name of the months */
  months: string[]
  /** First day to render on the week rows */
  firstDayOfWeek: number
  /** Short names of the days of the week */
  weekdaysShort: string[]
  /** Callback for firing on DOM change for calendar*/
  onDOMChange?: () => void
  /** Callback for firing on mount*/
  onMount?: () => void
  /** The day change handler */
  onChange: (checkInDate: string, checkOutDate: string) => void
  /** The day click handler */
  onDayClick?: (type: DatePickerType, date: string) => void
  /** The picker open handler */
  onOpen: (type: DatePickerType) => void
  /** Called when a check out date is selected */
  onCheckOutSelected?: () => void
  /** The form submit handler */
  onSubmit: () => void
  /** The callback for closing the overlay  */
  onClose: () => void
  /** Submit message, which you need to pass a FormattedMessageWrapper component */
  submitButtonMessage?: ReactNode
  /** Use react-virtuoso to virtualize the Months list */
  virtualize?: boolean
  /** The style of animation for opening and closing */
  animationType?: AnimationType
  showTotalPrices?: boolean
  shouldShowPrices?: boolean
  onMonthView?: (month: Date) => void
  isColorPriceDatePickerEnabled?: boolean
  DayInnerContainer?: DayInnerContainer
}

const DatePickerOverlayContent = lazy(
  () =>
    import(
      /* webpackChunkName: "DatePickerOverlayContent" */
      './DatePickerOverlayContent'
    )
)

export const DatePickerOverlay = ({
  checkIn,
  checkOut,
  isOpen,
  openedDatePickerType,
  months,
  firstDayOfWeek,
  weekdaysShort,
  onDOMChange,
  onMount,
  onChange,
  onDayClick,
  onOpen,
  onCheckOutSelected,
  onSubmit,
  onClose,
  submitButtonMessage = null,
  virtualize = false,
  animationType,
  showTotalPrices,
  shouldShowPrices,
  onMonthView,
  isColorPriceDatePickerEnabled,
  DayInnerContainer
}: Props) => {
  useEffect(() => {
    import(
      /* webpackChunkName: "DatePickerOverlayContent" */
      /* webpackPrefetch: true */
      './DatePickerOverlayContent'
    )
  }, [])

  return (
    <OverlayPage
      isOpen={isOpen}
      titleBarProps={{
        centerContent: (
          <FormattedMessageWrapper
            id="datePicker.selectDates"
            defaultMessage="Select dates"
          />
        )
      }}
      onClose={onClose}
      animationType={animationType}
      hasHeaderBoxShadow={false}
    >
      {isOpen && (
        <Suspense fallback={null}>
          <DatePickerOverlayContent
            virtualize={virtualize}
            checkIn={checkIn}
            checkOut={checkOut}
            firstDayOfWeek={firstDayOfWeek}
            months={months}
            openedDatePickerType={openedDatePickerType}
            weekdaysShort={weekdaysShort}
            onDOMChange={onDOMChange}
            onMount={onMount}
            onChange={onChange}
            onCheckOutSelected={onCheckOutSelected}
            onDatePickerOpen={onOpen}
            onDayClick={onDayClick}
            submitButtonMessage={submitButtonMessage}
            onSubmit={onSubmit}
            showTotalPrices={showTotalPrices}
            shouldShowPrices={shouldShowPrices}
            onMonthView={onMonthView}
            isColorPriceDatePickerEnabled={isColorPriceDatePickerEnabled}
            DayInnerContainer={DayInnerContainer}
          />
        </Suspense>
      )}
    </OverlayPage>
  )
}
